import { HomeOutlined, ListAlt } from '@mui/icons-material';
import { IRoute } from '../interfaces';
import Listings from '../pages/Listings/Listings';
import FAQ from '../pages/Landings/components/FAQ';
import About from '../pages/Landings/About';
import Policies from '../pages/Landings/Policies';
import Press from '../pages/Landings/Press';
import NotFound from '../pages/NotFound';
import Product from '../pages/Landings/Product';
import LandingV2 from '../pages/Landings/LandingV2';
import LandingV2Buyer from '../pages/Landings/LandingV2Buyer';

export const routes: Array<IRoute> = [
  {
    key: 'main-landing-route',
    title: 'Homepoint - Real Estate Your Way',
    path: '/',
    enabled: true,
    component: LandingV2,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'buying-route',
    title: 'Buying',
    path: '/buyers',
    enabled: true,
    component: LandingV2Buyer,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'listings-route',
    title: 'Listing',
    path: '/listings',
    enabled: true,
    component: Listings,
    icon: ListAlt,
    menu: false,
    protected: false,
  },
  {
    key: 'faqs-route',
    title: 'FAQs',
    path: '/faqs',
    enabled: true,
    component: FAQ,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'about-route',
    title: 'About Us',
    path: '/aboutus',
    enabled: true,
    component: About,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'policies-route',
    title: 'Policies',
    path: '/policies',
    enabled: true,
    component: Policies,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'press-route',
    title: 'Press',
    path: '/press',
    enabled: true,
    component: Press,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'product-route',
    title: 'Product',
    path: '/product',
    enabled: true,
    component: Product,
    icon: HomeOutlined,
    menu: false,
    protected: false,
  },
  {
    key: 'not-found',
    path: '/404',
    component: NotFound,
    icon: HomeOutlined,
    title: '404',
    enabled: true,
    menu: false,
    protected: false,
  },
];
