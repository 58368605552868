import { ArrowBack } from '@mui/icons-material';
import { Stack, IconButton } from '@mui/material';
import { useBackButton } from '../../contexts/BackButtonContext';

interface Props {
  children: any;
}

const BackButton: React.FC<Props> = ({ children }) => {
  const { isVisible, goBack } = useBackButton();

  return (
    <Stack direction="row" alignItems={'center'} spacing={1}>
      {isVisible && (
        <IconButton onClick={goBack}>
          <ArrowBack />
        </IconButton>
      )}
      {children}
    </Stack>
  );
};

export default BackButton;
