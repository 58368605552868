import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Step,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { addDays } from 'date-fns';
import offersService from '../../api/services/offers';
import { Close, InfoOutlined } from '@mui/icons-material';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import { baseUrl } from '../../api/api';
import { FilePondFile } from 'filepond';
import { Link } from 'react-router-dom';
import { listings } from '@prisma/client';
import BuyingPower from './BuyingPower';
import { useAuth } from '../../contexts/AuthContext';
import { useAppDispatch } from '../../store/store';
import { fetchOffers } from '../../store/slices/offers';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { fetchUploads } from '../../store/slices/uploads';

interface Props {
  listing: listings;
  buyer?: boolean;
}

const OfferModal = NiceModal.create<Props>((props) => {
  const modal = useModal();
  const { listing, buyer } = props;
  const listingId = listing.id;
  const isBuyer = !!buyer;
  const [success, setSuccess] = useState(false);
  const token = localStorage.getItem('jwtToken');
  const sessionId = useRef<string>(crypto.randomUUID());
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [pondFiles, setPondFiles] = useState<FilePondFile[]>([]);
  const steps = ['Upload Documents', 'Fill Details'];

  const stepValidationSchemas = (isBuyer: boolean) =>
    Yup.object({
      firstName: Yup.string().required(
        'First name is required. Please enter your first name.'
      ),
      lastName: Yup.string().required(
        'Last name is required. Please enter your last name.'
      ),
      phone: Yup.string()
        .required('Phone number is required')
        .test('is-valid-tel', 'Phone number is invalid', (value) =>
          matchIsValidTel(value)
        ),
      email: Yup.string()
        .email('Invalid email format. Please enter a valid email address.')
        .required('Email is required. Please enter your email address.'),
      price: Yup.number()
        .required('Offer price is required. Please enter the offer price.')
        .typeError('Offer price must be a valid number.'),
      bagentComm: Yup.number().when([], {
        is: () => !isBuyer,
        then: (schema) =>
          schema
            .required(
              // eslint-disable-next-line max-len
              "Buyer's agent commission is required. Please enter a valid percentage."
            )
            .typeError("Buyer's agent commission must be a valid number."),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
      earnestMoney: Yup.number()
        .required(
          'Earnest money is required. Please enter the earnest money amount.'
        )
        .typeError('Earnest money must be a valid number.'),
      concessions: Yup.number()
        .required(
          'Concessions amount is required. Please enter the concessions amount.'
        )
        .typeError('Concessions must be a valid number.'),
      paymentMethod: Yup.string().required('Please select a payment method.'),
      files: Yup.array().when([], {
        is: () => !isBuyer, // If not buyer, files are required
        then: (schema) =>
          schema
            .min(1, 'You must upload at least one document.')
            .required('At least one document is required for submission.'),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
      closingDate: Yup.date()
        .required('Closing date is required. Please select a closing date.')
        .typeError('Closing date must be a valid date.'),
      inspection: Yup.boolean().required(
        'Please specify if an inspection is required.'
      ),
      inspectionDays: Yup.number().when('inspection', {
        is: true,
        then: (schema) =>
          schema
            .required('Inspection days are required when inspection is true.')
            .min(1, 'Inspection days must be at least 1.')
            .max(30, 'Inspection days must not exceed 30.')
            .typeError('Inspection days must be a valid number.'),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
      loan: Yup.number().when('paymentMethod', {
        is: 'Financed',
        then: (schema) =>
          schema
            .required('Loan amount is required when financing.')
            .min(1, 'Loan amount must be at least $1.')
            .max(Yup.ref('price'), 'Loan amount cannot exceed offer price.'),
        otherwise: (schema) => schema.notRequired().nullable(),
      }),
    });

  const formik = useFormik({
    initialValues: {
      email: currentUser?.email ?? '',
      firstName: currentUser?.firstName ?? '',
      lastName: currentUser?.lastName ?? '',
      phone: currentUser?.phone ?? '',
      buyerFirstName: isBuyer ? currentUser?.firstName : '',
      buyerLastName: isBuyer ? currentUser?.lastName : '',
      buyerPhone: '',
      price: isBuyer ? listing.price.toString() : '',
      paymentMethod: '',
      files: [] as FilePondFile[],
      bagentComm: '',
      earnestMoney: isBuyer ? 1000 : '',
      closingDate: addDays(new Date(), 30),
      concessions: 0,
      inspection: isBuyer,
      inspectionDays: isBuyer ? 10 : 7,
      appliances: isBuyer,
      additionalTerms: '',
      contingent: false,
      loan: 0,
      expiresOn: addDays(new Date(), 4),
    },
    validationSchema: stepValidationSchemas(isBuyer),
    validateOnMount: true,
    onSubmit: async (values) => {
      const buyerPhone = isBuyer ? values.phone : values.buyerPhone;
      const formattedBuyerPhone = buyerPhone.replace(/ /g, '');

      try {
        const { files, inspection, inspectionDays, ...rest } = values;
        await offersService.post(
          {
            ...rest,
            ...(isBuyer
              ? {
                  buyerFirstName: values.buyerFirstName,
                  buyerLastName: values.buyerLastName,
                }
              : {}),
            sessionId: sessionId.current,
            price: parseInt(values.price),
            inspectionPeriod: inspection ? inspectionDays : null,
            bagentComm: isBuyer ? 0 : parseFloat(values.bagentComm),
            earnestMoney: parseInt(values.earnestMoney as string),
            buyerPhone: formattedBuyerPhone,
            listings: {
              connect: {
                id: listingId,
              },
            },
          },
          isBuyer
        );
        setSuccess(true);
        dispatch(fetchOffers());
        if (currentUser?.id) {
          dispatch(fetchUploads({ listingId, userId: currentUser?.id }));
        }
      } catch (e) {
        /* empty */
      }
    },
  });

  const handleNext = () => {
    if (activeStep === 0) {
      if (!isBuyer && formik.values.files.length === 0) {
        formik.setFieldError('files', 'You must upload at least one document.');
        return;
      }

      if (!isBuyer) {
        setConfirmOpen(true);
        return;
      }
    }
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    const existingFiles = formik.values.files.map((file) => ({
      source: file.serverId,
      options: {
        type: 'local',
        file: {
          name: file.filename,
          size: file.fileSize,
          type: file.fileType,
        },
      },
    }));
    setPondFiles(existingFiles as any);
  };

  useEffect(() => {
    if (activeStep === 1) {
      const dialogContent = document.querySelector('.MuiDialogContent-root');
      if (dialogContent) {
        dialogContent.scrollTo(0, 0);
      }
    }
  }, [activeStep]);

  return (
    <>
      <Dialog
        open={confirmOpen}
        onClose={() => setConfirmOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Transaction Broker</DialogTitle>
        <DialogContent>
          <Typography>
            Please confirm that your offer lists Homepoint as a{' '}
            <b>transaction broker</b>. Offers without Homepoint listed as a{' '}
            <b>transaction broker</b> will be rejected.
          </Typography>
        </DialogContent>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
          <Button variant="outlined" onClick={() => setConfirmOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setConfirmOpen(false);
              setActiveStep((prevStep) => prevStep + 1);
            }}
          >
            Confirm
          </Button>
        </Box>
      </Dialog>

      <Dialog
        open={modal.visible}
        onClose={() => modal.remove()}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h6" fontWeight={600}>
              Submit Offer {isBuyer ? 'for Review' : ''}
            </Typography>
            <IconButton onClick={() => modal.remove()}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {success ? (
            <Alert severity="success">
              {isBuyer
                ? // eslint-disable-next-line max-len
                  'Your offer has been submitted to Homepoint for review. We will contact you to consult on the offer.'
                : // eslint-disable-next-line max-len
                  `Your offer has been submitted successfully. You will receive further
              communication via email regarding the offer.`}
            </Alert>
          ) : (
            <>
              <Stepper activeStep={activeStep} sx={{ mb: 3 }}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 ? (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={500}>
                      Documents
                    </Typography>
                    {!isBuyer && (
                      <>
                        <Typography variant="body2">
                          We will automatically fill out the details from your
                          offer where applicable. You can upload additional
                          documents to support your offer.{' '}
                          <b>
                            Ensure that your offer lists Homepoint as a
                            transaction broker.
                          </b>
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FilePond
                      files={pondFiles as any}
                      onremovefile={(error, file) => {
                        const updatedFiles = formik.values.files.filter(
                          (f) => f.serverId !== file.serverId
                        );
                        formik.setFieldValue('files', updatedFiles);
                        setPondFiles([]);
                      }}
                      onupdatefiles={(fileItems) => {
                        setPondFiles(fileItems);
                      }}
                      onprocessfile={(error, file) => {
                        if (!error) {
                          try {
                            const res = JSON.parse(file.serverId);
                            const parsed = res[0].analyzeResult;
                            const fileKey = res[0].uploadResult.key;

                            // Only pick values if not a buyer or if fields are empty
                            const filteredResponse = _.pick(
                              parsed,
                              Object.keys(formik.initialValues)
                            );

                            // Don't overwrite user info if currentUser exists
                            const valuesToUpdate = currentUser
                              ? _.omit(filteredResponse, [
                                  'firstName',
                                  'lastName',
                                  'email',
                                  'phone',
                                ])
                              : filteredResponse;

                            formik.setValues({
                              ...formik.values,
                              ...valuesToUpdate,
                              files: [...formik.values.files, file],
                            });

                            return fileKey;
                          } catch (e) {
                            console.error(e);
                          }
                        }
                        return file.id;
                      }}
                      allowMultiple={true}
                      maxFiles={5}
                      allowRevert={false}
                      credits={false}
                      server={{
                        process: (
                          _fieldName,
                          file,
                          _metadata,
                          load,
                          error,
                          progress,
                          abort
                        ) => {
                          const formData = new FormData();
                          formData.append('listingId', listingId);
                          formData.append('type', 'OFFER_DOC');
                          formData.append('sessionId', sessionId.current);
                          formData.append('analyze', 'true');
                          formData.append('file', file);

                          const request = new XMLHttpRequest();
                          request.open('POST', `${baseUrl}/uploads`);
                          request.setRequestHeader(
                            'Authorization',
                            `Bearer ${token}`
                          );

                          request.upload.onprogress = (e) => {
                            progress(e.lengthComputable, e.loaded, e.total);
                          };

                          request.onload = function () {
                            if (request.status >= 200 && request.status < 300) {
                              load(request.responseText);
                            } else {
                              error('Upload failed');
                            }
                          };

                          request.send(formData);

                          return {
                            abort: () => {
                              request.abort();
                              abort();
                            },
                          };
                        },
                      }}
                      name="files"
                      // eslint-disable-next-line max-len
                      labelIdle={`Drag & Drop your ${
                        isBuyer ? '' : 'offer, '
                        // eslint-disable-next-line max-len
                      } pre-approval, etc., or <span class="filepond--label-action">Browse</span>`}
                    />
                    <Typography variant="body2" color="error">
                      {formik.touched.files && (formik.errors.files as string)}
                    </Typography>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={2}>
                  {!isBuyer && (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="body1" fontWeight={500}>
                          Buyer&apos;s Agent Info
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="firstName"
                          label="First Name"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          fullWidth
                          required
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="lastName"
                          label="Last Name"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          fullWidth
                          required
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="email"
                          label="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          fullWidth
                          required
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiTelInput
                          value={formik.values.phone}
                          onChange={(value) =>
                            formik.setFieldValue('phone', value)
                          }
                          onlyCountries={['US']}
                          defaultCountry="US"
                          label="Phone Number"
                          forceCallingCode={true}
                          fullWidth
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={500}>
                      Buyer&apos;s Info
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={isBuyer ? 6 : 4}>
                    <TextField
                      name="buyerFirstName"
                      label="First Name"
                      value={formik.values.buyerFirstName}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={isBuyer ? 6 : 4}>
                    <TextField
                      name="buyerLastName"
                      label="Last Name"
                      value={formik.values.buyerLastName}
                      onChange={formik.handleChange}
                      fullWidth
                    />
                  </Grid>
                  {!isBuyer && (
                    <Grid item xs={12} sm={4}>
                      <MuiTelInput
                        value={formik.values.buyerPhone}
                        onChange={(value) =>
                          formik.setFieldValue('buyerPhone', value)
                        }
                        onlyCountries={['US']}
                        defaultCountry="US"
                        label="Buyer Phone Number"
                        forceCallingCode={true}
                        fullWidth
                        helperText={
                          formik.touched.buyerPhone && formik.errors.buyerPhone
                        }
                        error={
                          formik.touched.buyerPhone &&
                          Boolean(formik.errors.buyerPhone)
                        }
                      />
                    </Grid>
                  )}
                  {isBuyer && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="email"
                          label="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          fullWidth
                          required
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MuiTelInput
                          value={formik.values.phone}
                          onChange={(value) =>
                            formik.setFieldValue('phone', value)
                          }
                          onlyCountries={['US']}
                          defaultCountry="US"
                          label="Phone Number"
                          forceCallingCode={true}
                          fullWidth
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          error={
                            formik.touched.phone && Boolean(formik.errors.phone)
                          }
                        />
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={500}>
                      Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl>
                      <FormLabel id="paymentMethod">Payment Method</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="paymentMethod"
                        name="paymentMethod"
                        value={formik.values.paymentMethod}
                        onChange={formik.handleChange}
                      >
                        <FormControlLabel
                          value={'Financed'}
                          control={<Radio />}
                          label="Financed"
                        />
                        <FormControlLabel
                          value={'Cash'}
                          control={<Radio />}
                          label="Cash"
                        />
                      </RadioGroup>
                      {formik.touched.paymentMethod &&
                        formik.errors.paymentMethod && (
                          <Typography variant="body2" color="error">
                            {formik.errors.paymentMethod}
                          </Typography>
                        )}
                    </FormControl>
                  </Grid>
                  {formik.values.paymentMethod === 'Financed' && (
                    <Grid item xs={12} sm={8}>
                      <TextField
                        name="loan"
                        label="Loan Amount"
                        value={formik.values.loan}
                        onChange={formik.handleChange}
                        type="number"
                        fullWidth
                        required
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        helperText={formik.touched.loan && formik.errors.loan}
                        error={
                          formik.touched.loan && Boolean(formik.errors.loan)
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      name="price"
                      label="Offer Price"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      type="number"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      helperText={formik.touched.price && formik.errors.price}
                      error={
                        formik.touched.price && Boolean(formik.errors.price)
                      }
                    />
                    {isBuyer && listing.price > 0 && (
                      <BuyingPower
                        listing={listing}
                        offerPrice={parseInt(formik.values.price)}
                        handlePriceChange={(price) =>
                          formik.setFieldValue('price', price)
                        }
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="earnestMoney"
                      label="Earnest Money"
                      value={formik.values.earnestMoney}
                      onChange={formik.handleChange}
                      type="number"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      helperText={
                        formik.touched.earnestMoney &&
                        formik.errors.earnestMoney
                          ? formik.errors.earnestMoney
                          : // eslint-disable-next-line max-len
                            'Earnest money is a deposit to show the seller you are serious about purchasing the property. Think of it like a good-faith deposit.'
                      }
                      error={
                        formik.touched.earnestMoney &&
                        Boolean(formik.errors.earnestMoney)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="concessions"
                      label="Concessions"
                      value={formik.values.concessions}
                      onChange={formik.handleChange}
                      type="number"
                      fullWidth
                      required
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      helperText={
                        formik.touched.concessions && formik.errors.concessions
                          ? formik.errors.concessions
                          : // eslint-disable-next-line max-len
                            'Concessions are when you ask the seller to help cover some of your costs when buying a home.'
                      }
                      error={
                        formik.touched.concessions &&
                        Boolean(formik.errors.concessions)
                      }
                    />
                  </Grid>
                  {!isBuyer && (
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="bagentComm"
                        label="Buyer's Agent %"
                        value={formik.values.bagentComm}
                        onChange={formik.handleChange}
                        type="number"
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        helperText={
                          formik.touched.bagentComm && formik.errors.bagentComm
                            ? formik.errors.bagentComm
                            : `Offered BAC is ${listing.commission}%`
                        }
                        error={
                          formik.touched.bagentComm &&
                          Boolean(formik.errors.bagentComm)
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={isBuyer ? 12 : 4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label="Closing Date"
                        value={formik.values.closingDate}
                        onChange={(value) =>
                          formik.setFieldValue('closingDate', value)
                        }
                        sx={{
                          width: '100%',
                        }}
                        slotProps={{ textField: { required: true } }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  {!isBuyer && (
                    <Grid item xs={12} sm={4}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Offer Expiration Date"
                          value={formik.values.expiresOn}
                          onChange={(value) =>
                            formik.setFieldValue('expiresOn', value)
                          }
                          sx={{
                            width: '100%',
                          }}
                          slotProps={{ textField: { required: true } }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography variant="body1" fontWeight={500}>
                      Additional
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl>
                      <FormLabel id="inspection">Inspection Required</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="inspection"
                        name="inspection"
                        value={formik.values.inspection.toString()}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'inspection',
                            e.target.value === 'true'
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name="inspectionDays"
                      label="Inspection Days"
                      value={formik.values.inspectionDays}
                      disabled={!formik.values.inspection}
                      onChange={formik.handleChange}
                      type="number"
                      fullWidth
                      required
                      error={
                        formik.touched.inspectionDays &&
                        Boolean(formik.errors.inspectionDays)
                      }
                      helperText={
                        formik.touched.inspectionDays &&
                        formik.errors.inspectionDays
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl>
                      <FormLabel id="appliances">Appliances Required</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="appliances"
                        name="appliances"
                        value={formik.values.appliances.toString()}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'appliances',
                            e.target.value === 'true'
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl>
                      <FormLabel id="contingent">
                        <Stack
                          direction="row"
                          alignItems={'center'}
                          spacing={1}
                        >
                          <Typography>Contingent</Typography>
                          <CustomTooltip
                            title={
                              <Box p={1}>
                                <Typography variant="body2">
                                  This includes contingency on another contract
                                  closing, a property selling, etc.
                                </Typography>
                              </Box>
                            }
                          >
                            <InfoOutlined fontSize="small" />
                          </CustomTooltip>
                        </Stack>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="contingent"
                        name="contingent"
                        value={formik.values.contingent.toString()}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'contingent',
                            e.target.value === 'true'
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="additionalTerms"
                      label="Additional Terms"
                      value={formik.values.additionalTerms}
                      onChange={formik.handleChange}
                      fullWidth
                      multiline
                      rows={2}
                      error={
                        formik.touched.additionalTerms &&
                        Boolean(formik.errors.additionalTerms)
                      }
                      helperText={
                        formik.touched.additionalTerms &&
                        formik.errors.additionalTerms
                      }
                    />
                  </Grid>
                </Grid>
              )}

              <Stack direction="row" justifyContent={'space-between'} pt={2}>
                {!isMobile && (
                  <img
                    // eslint-disable-next-line max-len
                    src="https://www.nar.realtor/sites/default/files/downloadable/equal-housing-opportunity-logo-1200w.jpg"
                    width="50"
                  />
                )}
                <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
                  {!isBuyer && activeStep === 1 && (
                    <Typography
                      variant="caption"
                      textAlign={isMobile ? 'center' : 'right'}
                      alignContent={'center'}
                    >
                      By clicking submit, you confirm that you have read and
                      agree to our <Link to={'/policies'}>policies</Link>.
                    </Typography>
                  )}
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => modal.hide()}
                  >
                    Cancel
                  </Button>
                  {activeStep > 0 && (
                    <Button variant="outlined" onClick={handleBack}>
                      Back
                    </Button>
                  )}
                  {activeStep === steps.length - 1 ? (
                    <Button
                      variant="contained"
                      onClick={() => formik.handleSubmit()}
                    >
                      {isBuyer ? 'Submit for Review' : 'Submit'}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleNext}
                      disabled={
                        activeStep === 0 && formik.values.files.length === 0
                      }
                    >
                      Next
                    </Button>
                  )}
                </Stack>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
});

export default OfferModal;
