/* eslint-disable max-len */
import {
  Box,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import useIsMobile from '../../../hooks/useIsMobile';

const HIWSellingV2 = ({ type = 'seller' }: { type?: 'seller' | 'buyer' }) => {
  const theme = useTheme();
  const mobile = useIsMobile();

  const sellerSteps = [
    {
      title: 'List Your Home',
      description:
        'Get your home on the MLS with pro photos, expert pricing, connected lockbox, and a yard sign.',
    },
    {
      title: 'Get Maximum Exposure',
      description:
        'We market your home across MLS, social media, and real estate sites.',
    },
    {
      title: 'Review & Accept Offers',
      description:
        'Easily compare offers on our platform & negotiate confidently with expert support.',
    },
    {
      title: 'Close & Celebrate',
      description: 'Manage the entire transaction to closing on our platform',
    },
  ];

  const buyerSteps = [
    {
      title: 'Search for Your Home',
      description:
        'Search for your home on your favorite real estate sites like Zillow or Realtor.com.',
    },
    {
      title: 'Schedule a Tour',
      description:
        'Tour with an agent who takes no commission. Tours are $50 / each and refunded if you buy a home.',
    },
    {
      title: 'Make an Offer',
      description:
        'Work with our experts to craft competitive offers and negotiate terms.',
    },
    {
      title: 'Close & Move In',
      description:
        'Navigate inspections, paperwork, and closing with our platform and expert support.',
    },
  ];

  const steps = type === 'seller' ? sellerSteps : buyerSteps;

  return (
    <Container maxWidth="lg">
      <Stack rowGap={4} alignItems="center">
        <Typography
          variant={mobile ? 'h5' : 'h4'}
          fontWeight={700}
          textAlign="center"
        >
          {type === 'seller' ? 'How Selling Works' : 'How Buying Works'}
        </Typography>
        <Grid container spacing={4} position="relative">
          {/* Connector lines */}
          {!mobile && (
            <Box
              sx={{
                position: 'absolute',
                top: '25%',
                left: '15%',
                right: '15%',
                height: 2,
                backgroundColor: theme.palette.primary.main,
                zIndex: 0,
              }}
            />
          )}
          {steps.map((step, index) => (
            <Grid item xs={12} sm={3} key={index}>
              <Card
                sx={{
                  height: '100%',
                  p: 3,
                  borderRadius: 2,
                  transition: 'transform 0.2s',
                  position: 'relative',
                  zIndex: 1,
                  backgroundColor: theme.palette.background.paper,
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[4],
                  },
                }}
              >
                <Stack spacing={2} alignItems="center" textAlign="center">
                  <Typography
                    variant="h3"
                    component="div"
                    color="primary"
                    sx={{
                      fontWeight: 700,
                      backgroundColor: theme.palette.background.paper,
                      width: 60,
                      height: 60,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Typography variant="h6" fontWeight={600}>
                    {step.title}
                  </Typography>
                  <Typography variant="body1" color="text.primary">
                    {step.description}
                  </Typography>
                </Stack>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* <Button
          variant="contained"
          color="highlight"
          size="large"
          sx={{
            fontWeight: 600,
            fontSize: 20,
            maxWidth: '300px',
            mt: 4,
          }}
        >
          List Today, Sell When You're Ready
        </Button> */}
      </Stack>
    </Container>
  );
};

export default HIWSellingV2;
