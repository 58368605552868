import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { offers } from '@prisma/client';
import offersService from '../../api/services/offers';
import { get, lowerCase } from 'lodash';
import Disclosures from './Disclosures';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import useIsMobile from '../../hooks/useIsMobile';
import { Close } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectListingById } from '../../store/selectors/listings';
import { RootState } from '../../store/store';

export enum OFFER_CONFIRM_MODAL_TYPE {
  ACCEPT,
  DECLINE,
  COUNTER,
}

interface Props {
  offer: offers;
  handleOnSubmit: (offer: offers) => void;
  type: OFFER_CONFIRM_MODAL_TYPE;
}

const OfferConfirmModal = NiceModal.create<Props>((props) => {
  const modal = useModal();
  const { offer, handleOnSubmit, type } = props;
  const [loading, setLoading] = useState(false);
  const isMobile = useIsMobile();
  const listing = useSelector((state: RootState) =>
    selectListingById(state)(offer.listingId)
  );
  const showLeadForm = !!listing && listing.yearBuilt < 1978;

  const getText = () => {
    if (type === OFFER_CONFIRM_MODAL_TYPE.COUNTER) {
      return 'Counter';
    }
    return type === OFFER_CONFIRM_MODAL_TYPE.DECLINE ? 'Decline' : 'Accept';
  };

  const text = getText();

  const submit = async () => {
    if (type === OFFER_CONFIRM_MODAL_TYPE.COUNTER) {
      await counterOffer();
    } else if (type === OFFER_CONFIRM_MODAL_TYPE.DECLINE) {
      await declineOffer();
    } else {
      await acceptOffer();
    }
  };

  const counterOffer = async () => {
    await offersService.patch(offer.id, {
      status: 'COUNTERED',
      counter: get(formik.values, 'counter'),
    });
    handleOnSubmit(offer);
    modal.remove();
  };

  const acceptOffer = async () => {
    await offersService.patch(offer.id, { status: 'ACCEPTED' });
    handleOnSubmit(offer);
    modal.remove();
  };

  const declineOffer = async () => {
    await offersService.patch(offer.id, { status: 'DECLINED' });
    handleOnSubmit(offer);
    modal.remove();
  };

  const validationSchema = {
    [OFFER_CONFIRM_MODAL_TYPE.DECLINE]: Yup.object({}),
    [OFFER_CONFIRM_MODAL_TYPE.COUNTER]: Yup.object({
      counter: Yup.string().required('You must enter counter details'),
    }),
    [OFFER_CONFIRM_MODAL_TYPE.ACCEPT]: Yup.object({
      leadSigned: showLeadForm
        ? Yup.boolean().isTrue('You must sign your Lead Paint Disclosure.')
        : Yup.boolean(),
      costsSigned: Yup.boolean().isTrue(
        'You must sign your Closing Costs Estimated.'
      ),
      disclosureDescription: Yup.string().required(
        // eslint-disable-next-line max-len
        'Disclosures are required. If you have nothing to disclose, type in none.'
      ),
    }),
  };

  const initValues = {
    [OFFER_CONFIRM_MODAL_TYPE.DECLINE]: {},
    [OFFER_CONFIRM_MODAL_TYPE.COUNTER]: {
      counter: '',
    },
    [OFFER_CONFIRM_MODAL_TYPE.ACCEPT]: {
      costsSigned: false,
      leadSigned: false,
      disclosureDescription: '',
    },
  };

  const formik = useFormik({
    initialValues: initValues[type],
    validationSchema: validationSchema[type],
    onSubmit: async (values) => {
      setLoading(true);
      try {
        await submit();
        setLoading(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    },
  });

  const hideActions = get(formik.values, 'leadSigned') === null;

  return (
    <Dialog
      open={modal.visible}
      onClose={() => modal.remove()}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography variant="h6" fontWeight={600}>
            {text} Offer
          </Typography>
          <IconButton onClick={() => modal.remove()}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        {type === OFFER_CONFIRM_MODAL_TYPE.DECLINE &&
          `Are you sure you want to ${lowerCase(text)} this offer?`}
        {type === OFFER_CONFIRM_MODAL_TYPE.COUNTER && (
          <>
            <Typography>
              Congratulations! You&apos;ve received an offer. If you counter an
              offer that means you do not accept the terms as outlined by the
              offer, and you&apos;d like to propose changes. Once you counter an
              offer, it voids the original offer from the buyer, so if you
              decide to counter choose your terms wisely. You cannot go back and
              accept the original offer once a counter offer has been sent to
              the buyer.
            </Typography>
            <br />
            <ul style={{ marginTop: -12, marginBottom: 20 }}>
              <li>
                <strong>Evaluate the Offer:</strong> Review the price,
                contingencies, and closing timeline to understand the buyer’s
                terms and identify areas for negotiation.
              </li>
              <li>
                <strong>Set Your Priorities:</strong> Decide what aspects of the
                deal are most important to you—whether it&apos;s the price,
                closing date, or other terms.
              </li>
              <li>
                <strong>Be Realistic and Reasonable:</strong> Counter with terms
                that reflect the current market and are likely to be accepted,
                avoiding extreme positions.
              </li>
            </ul>
            <TextField
              fullWidth
              placeholder="Details"
              rows={3}
              multiline
              name="counter"
              value={get(formik.values, 'counter')}
              onChange={formik.handleChange}
              error={
                !!get(formik.errors, 'counter') &&
                Boolean(get(formik.errors, 'counter'))
              }
              helperText={
                get(formik.touched, 'counter') && get(formik.errors, 'counter')
              }
            />
          </>
        )}
        {type === OFFER_CONFIRM_MODAL_TYPE.ACCEPT && (
          <>
            <Typography>
              Congratulations on accepting an offer! Please follow the steps
              below.
            </Typography>
            <Box pt={2}>
              <Disclosures
                formik={formik}
                offer={offer}
                showLeadForm={showLeadForm}
              />
            </Box>
          </>
        )}
      </DialogContent>
      {!hideActions && (
        <DialogActions>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button onClick={() => formik.handleSubmit()}>{text}</Button>
              <Button color="error" onClick={() => modal.remove()}>
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
});

export default OfferConfirmModal;
