import { Box, Card, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FilesTable from './components/FilesTable';
import { baseUrl } from '../../../../../../api/api';
import { useAppDispatch } from '../../../../../../store/store';
import { fetchUploads } from '../../../../../../store/slices/uploads';

const StyledFilePond = styled(FilePond)(() => ({
  '.filepond--item': {
    width: 'calc(33% - 0.3em)',
  },
  '.filepond--wrapper': {
    height: 50,
  },
}));

interface Props {
  listingId: string;
}

const Files: React.FC<Props> = ({ listingId }) => {
  const { id } = useParams();
  const [files, setFiles] = useState([]);
  const dispatch = useAppDispatch();
  const token = localStorage.getItem('jwtToken');

  const refreshUploads = () => {
    dispatch(fetchUploads({ listingId, userId: id! }));
  };

  useEffect(() => {
    refreshUploads();
  }, []);

  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 xs={12}>
          <Typography variant="h6">Documents</Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Card>
            <Box sx={{ p: 2, pb: 0 }}>
              <StyledFilePond
                files={files as any}
                onupdatefiles={(e) => setFiles([...files, ...e] as any)}
                allowMultiple={true}
                imagePreviewMaxHeight={150}
                maxFiles={10}
                allowRevert={false}
                credits={false}
                server={{
                  process: (
                    _fieldName,
                    file,
                    _metadata,
                    load,
                    error,
                    progress,
                    abort
                  ) => {
                    const formData = new FormData();
                    formData.append('listingId', listingId);
                    formData.append('userId', id!);
                    formData.append('type', 'TRANSACTION_DOC');
                    formData.append('analyze', 'true');
                    formData.append('file', file);

                    const request = new XMLHttpRequest();
                    request.open('POST', `${baseUrl}/uploads`);
                    request.setRequestHeader(
                      'Authorization',
                      `Bearer ${token}`
                    );

                    request.upload.onprogress = (e) => {
                      progress(e.lengthComputable, e.loaded, e.total);
                    };

                    request.onload = function () {
                      if (request.status >= 200 && request.status < 300) {
                        load(request.responseText);
                      } else {
                        error('Upload failed');
                      }
                    };

                    request.send(formData);

                    return {
                      abort: () => {
                        request.abort();
                        abort();
                      },
                    };
                  },
                }}
                onprocessfile={() => refreshUploads()}
                name="files"
                // eslint-disable-next-line max-len
                labelIdle='Drag & Drop your files to upload or <span class="filepond--label-action">Browse</span>'
              />
            </Box>
            <FilesTable listingId={listingId} />
          </Card>
        </Grid2>
        <Grid2 xs={12}></Grid2>
      </Grid2>
    </>
  );
};

export default Files;
