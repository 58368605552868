import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../store/store';
import { selectBuyerDetailsById } from '../../../../../store/selectors/buyers';
import {
  Card,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  Dialog,
  useTheme,
  useMediaQuery,
  Chip,
} from '@mui/material';
import { fetchMessages } from '../../../../../store/slices/messages';
import { sortBy, startCase } from 'lodash';
import Messages from './Messages';
import Showing from './Showing';
import Offer from './Offer';
import { ArrowBack, OpenInFull } from '@mui/icons-material';
import Files from './Files/Files';
import { fetchOffers } from '../../../../../store/slices/offers';
import listingsService, {
  listingsWithUploads,
} from '../../../../../api/services/listings';
import { fetchShowings } from '../../../../../store/slices/showings';
import _ from 'lodash';
import BackButton from '../../../../../components/BackButton/BackButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSocket } from '../../../../../api/socket';
import { useAuth } from '../../../../../contexts/AuthContext';
import { useBackButton } from '../../../../../contexts/BackButtonContext';
import ListingCard from '../../../../../components/ListingCard/ListingCard';
import Grid2 from '@mui/material/Unstable_Grid2';
import TransactionStatus from '../../../../Transactions/TransactionStatus';
// eslint-disable-next-line max-len
import { selectTransactions } from '../../../../../store/selectors/transactions';
import { fetchTransactions } from '../../../../../store/slices/transactions';

const Detail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const details = useSelector((state: RootState) =>
    selectBuyerDetailsById(id!)(state)
  );

  const [listing, setListing] = useState<listingsWithUploads | null>(null);
  const [loading, setLoading] = useState(true);
  const [messagesFullscreen, setMessagesFullscreen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { connect, socket } = useSocket();
  const { currentUser } = useAuth();
  const { goBack } = useBackButton();
  const isPbuyer = currentUser?.type === 'PBUYER';

  const fetchListing = async () => {
    const listing = await listingsService.get({
      id: details.listingId,
    });
    setListing(listing);
  };

  const transactions = useSelector((state: RootState) =>
    selectTransactions(state)
  );

  const fetchData = async () => {
    await Promise.all([
      dispatch(fetchMessages()),
      dispatch(fetchOffers()),
      dispatch(fetchShowings()),
      dispatch(fetchTransactions()),
    ]);
  };

  useEffect(() => {
    const initialize = async () => {
      await fetchData();
    };
    initialize();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (details.listingId && !listing) {
        await fetchListing();
        setLoading(false);
      }
    };
    initialize();
  }, [details]);

  useEffect(() => {
    // Connect socket and fetch initial messages
    connect();
    dispatch(fetchMessages());

    // Listen for new messages
    socket.on('messages created', () => {
      dispatch(fetchMessages());
    });

    // Cleanup listener and disconnect socket when component unmounts
    return () => {
      socket.off('messages created');
    };
  }, [dispatch]);

  const messages = sortBy(details?.messages, 'createdAt');

  if (loading || !listing) {
    return (
      <Grid2 container spacing={2}>
        <Grid2 xs={12}>
          <BackButton>
            <Typography variant="h6" fontWeight={500}>
              {details.buyer.firstName} {details.buyer.lastName}
            </Typography>
          </BackButton>
        </Grid2>
        <Grid2 xs={12}>
          <Card sx={{ pl: 2, pr: 2 }}>
            <Skeleton height={150} />
          </Card>
        </Grid2>
        <Grid2 xs={12}>
          <Card sx={{ pl: 2, pr: 2 }}>
            <Skeleton height={150} />
          </Card>
        </Grid2>
        <Grid2 xs={12}>
          <Card sx={{ pl: 2, pr: 2 }}>
            <Skeleton height={150} />
          </Card>
        </Grid2>
      </Grid2>
    );
  }

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <Stack direction="row" alignItems={'center'} spacing={1}>
          {currentUser?.type !== 'PBUYER' && (
            <IconButton onClick={goBack}>
              <ArrowBack />
            </IconButton>
          )}
          <Typography variant="h6" fontWeight={500}>
            {details.buyer.firstName} {details.buyer.lastName}
          </Typography>
        </Stack>
      </Grid2>
      {isPbuyer && (
        <Grid2 xs={12}>
          <Card sx={{ height: '100%' }}>
            <ListingCard
              listing={listing}
              slim={true}
              showViewButton={true}
              showEditButton={false}
              showAdjustPriceButton={false}
            />
            <TransactionStatus
              transaction={transactions[0]}
              listing={listing}
              isPBuyer={true}
            />
          </Card>
        </Grid2>
      )}
      <Grid2 xs={12}>
        <Card sx={{ padding: 2 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography variant="h6">Showing</Typography>
            {details.showings && (
              <Chip
                label={
                  <Typography variant="body2" fontWeight={600}>
                    {_.startCase(details.showings.type.toLowerCase())}
                  </Typography>
                }
                color="primary"
              />
            )}
          </Stack>
          <Showing showing={details.showings} />
        </Card>
      </Grid2>
      <Grid2 xs={12}>
        <Card sx={{ padding: 2 }}>
          <Offer offer={details.offers} listing={listing} />
        </Card>
      </Grid2>
      <Grid2 xs={12}>
        <Files listingId={details.listingId} />
      </Grid2>
      <Grid2 xs={12}>
        <Card sx={{ padding: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">Messages</Typography>
            {isMobile && (
              <IconButton onClick={() => setMessagesFullscreen(true)}>
                <OpenInFull fontSize="small" />
              </IconButton>
            )}
          </Stack>
          <Messages
            messages={messages}
            listing={listing}
            buyerId={id!}
            fullscreen={false}
          />
        </Card>
      </Grid2>

      <Dialog
        fullScreen
        open={messagesFullscreen}
        onClose={() => setMessagesFullscreen(false)}
      >
        <Stack sx={{ height: '100%', p: 2 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Typography variant="h6">Messages</Typography>
            <IconButton onClick={() => setMessagesFullscreen(false)}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Messages
            messages={messages}
            listing={listing}
            buyerId={id!}
            fullscreen={true}
          />
        </Stack>
      </Dialog>
    </Grid2>
  );
};

export default Detail;
