import {
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { formatCurrency } from '../../../../utils/formatCurrency';
import { listings } from '@prisma/client';
// eslint-disable-next-line max-len
import ExternalStatus from '../../../../components/ExternalStatus/ExternalStatus';
import ListingStatus from '../../../../components/ListingStatus/ListingStatus';
import { isBefore } from 'date-fns';

interface Props {
  listing: listings;
  slim?: boolean;
}

const getPhotoDate = (listing: listings) => {
  if (listing.photoDate) {
    const photoDate = new Date(listing.photoDate);
    if (isBefore(photoDate, new Date())) {
      return 'Completed';
    }
    return new Date(listing.photoDate).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  }
  return 'Not set';
};

const MyListing: React.FC<Props> = ({ listing, slim }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h6" fontWeight={600}>
            {listing.address}
          </Typography>
          {!slim && (
            <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
              <ListingStatus status={listing.status} />
              {listing.status === 'APPROVED' && (
                <ExternalStatus status={listing.externalStatus} />
              )}
            </Stack>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Stack direction="row" spacing={1}>
          <Typography variant="body2" fontWeight={600}>
            Price:
          </Typography>
          <Typography variant="body2">
            {listing.price === 0 ? 'TBD' : formatCurrency(listing.price ?? 0)}
          </Typography>
        </Stack>
      </Grid>
      {!slim && (
        <Grid item xs={12} sm={9}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={600}>
              Photos Date:
            </Typography>
            <Typography variant="body2">{getPhotoDate(listing)}</Typography>
          </Stack>
        </Grid>
      )}
      {!slim && (
        <Grid item xs={12} sm={9}>
          <Stack direction="row" spacing={1}>
            <Typography variant="body2" fontWeight={600}>
              Live Date:
            </Typography>
            <Typography variant="body2">
              {listing.liveDate
                ? new Date(listing.liveDate).toLocaleDateString()
                : 'Not set'}
            </Typography>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default MyListing;
