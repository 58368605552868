import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Link,
  useMediaQuery,
  useTheme,
  Stack,
  Box,
  Divider,
} from '@mui/material';
import { FileTypeChip } from './FileTypeChip';
import { useSelector } from 'react-redux';
// eslint-disable-next-line max-len
import { selectUploadsByListingId } from '../../../../../../../store/selectors/uploads';
import { RootState } from '../../../../../../../store/store';

interface Props {
  listingId: string;
}

const FilesTable: React.FC<Props> = ({ listingId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const docs = useSelector((state: RootState) =>
    selectUploadsByListingId(state, listingId)
  );

  if (isMobile) {
    return (
      <Stack spacing={2}>
        {docs.length === 0 && <Typography>No documents</Typography>}
        {docs.map((doc) => (
          <>
            <Box key={doc.id} p={2}>
              <Stack spacing={1}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    fontWeight={600}
                  >
                    Name
                  </Typography>
                  <Typography variant="body2">{doc.fileName}</Typography>
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    fontWeight={600}
                  >
                    Type
                  </Typography>
                  {doc.docType ? (
                    <FileTypeChip docType={doc.docType} />
                  ) : (
                    <Typography variant="body2">Unknown</Typography>
                  )}
                </Stack>

                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    fontWeight={600}
                  >
                    Submitted
                  </Typography>
                  <Typography variant="body2">
                    {new Date(doc.createdAt).toLocaleString()}
                  </Typography>
                </Stack>

                <Link
                  target="_blank"
                  href={doc.url}
                  rel="noreferrer"
                  sx={{ alignSelf: 'flex-start', width: '100%' }}
                >
                  <Button variant="outlined" fullWidth>
                    View
                  </Button>
                </Link>
              </Stack>
            </Box>
            <Divider />
          </>
        ))}
      </Stack>
    );
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="body2" fontWeight={600}>
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" fontWeight={600}>
                  Type
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" fontWeight={600}>
                  Submitted
                </Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {docs.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography>No documents</Typography>
                </TableCell>
              </TableRow>
            )}
            {docs.map((doc) => {
              return (
                <TableRow
                  key={doc.id}
                  sx={{ '& > *': { cursor: 'pointer' } }}
                  hover={true}
                  //   onClick={() => navigate(`/buyers/${buyer.id}`)}
                >
                  <TableCell>{doc.fileName}</TableCell>
                  <TableCell>
                    {doc.docType ? (
                      <FileTypeChip docType={doc.docType} />
                    ) : (
                      <Typography variant="body2">Unknown</Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(doc.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right' }}>
                    <Link target="_blank" href={doc.url} rel="noreferrer">
                      <Button variant="outlined">View</Button>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default FilesTable;
