import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import TransactionStatus from './TransactionStatus';
import { fetchTransactions } from '../../store/slices/transactions';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectTransactions } from '../../store/selectors/transactions';
import { RootState, useAppDispatch } from '../../store/store';
import InspectionCard from './components/InspectionCard';
import { STEP_LABELS } from '../../utils/mapTransactionStatus';
import { fetchStatus } from '../../store/slices/status';
import { selectStatusByListingId } from '../../store/selectors/status';
import { ChevronLeft, WarningAmberOutlined } from '@mui/icons-material';

const TransactionDashboard: React.FC = () => {
  const transactions = useSelector((state: RootState) =>
    selectTransactions(state)
  );
  const status = useSelector((state: RootState) =>
    selectStatusByListingId(state, transactions[0].listingId)
  );
  const dispatch = useAppDispatch();
  const transaction = transactions[0];
  const [expanded, setExpanded] = useState<string | false>(false);

  useEffect(() => {
    dispatch(fetchTransactions());
    if (transaction?.listingId) {
      dispatch(fetchStatus(transaction.listingId));
    }
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const sections = [
    {
      title: STEP_LABELS.inspection,
      component: InspectionCard,
    },
    {
      title: STEP_LABELS.appraisal,
      component: null,
    },
    {
      title: STEP_LABELS.financing,
      component: null,
    },
    {
      title: STEP_LABELS.title,
      component: null,
    },
    {
      title: STEP_LABELS.closing,
      component: null,
    },
  ];

  if (!transaction) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={2}>
      <Card>
        {/* <TransactionStatus transaction={transaction} listing={status} /> */}
      </Card>
      {sections.map((section) => (
        <Card key={section.title}>
          <Accordion
            expanded={expanded === section.title}
            onChange={handleChange(section.title)}
          >
            <AccordionSummary>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                width="100%"
                alignItems="center"
                spacing={2}
              >
                <Stack direction="row" spacing={2} alignItems={'center'}>
                  <Typography variant="h6">{section.title}</Typography>
                  <WarningAmberOutlined />
                  <Typography variant="body1">Needed</Typography>
                </Stack>
                <ChevronLeft
                  sx={{
                    transform:
                      expanded === section.title
                        ? 'rotate(-90deg)'
                        : 'rotate(90deg)',
                    transition: 'transform 0.3s',
                  }}
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {section.component && (
                <section.component transaction={transaction} />
              )}
            </AccordionDetails>
          </Accordion>
        </Card>
      ))}
    </Stack>
  );
};

export default TransactionDashboard;
