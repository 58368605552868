import { useEffect } from 'react';

/**
 * Fetches the latest deployed version from `version.json` and compares it to the currently running version.
 * If a new version is detected, the app refreshes.
 */
const checkForUpdate = async () => {
  try {
    const response = await fetch('/version.json', {
      cache: 'no-cache',
      headers: { 'Cache-Control': 'no-cache' },
    });

    if (!response.ok) {
      console.error('Failed to fetch version.json');
      return;
    }

    const data = await response.json();
    const deployedVersion = data.version;
    const currentVersion = import.meta.env.VITE_APP_VERSION || '0.0.0';

    console.log(
      `Current Version: ${currentVersion}, Deployed Version: ${deployedVersion}`
    );

    if (deployedVersion !== currentVersion) {
      console.log('New version detected! Reloading...');
      window.location.reload();
    }
  } catch (error) {
    console.error('Error checking for updates:', error);
  }
};

/**
 * Hook to automatically check for updates periodically.
 */
const useVersionCheck = () => {
  useEffect(() => {
    // Add event listener for visibility change
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkForUpdate();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
};

export default useVersionCheck;
