import { Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import useIsMobile from '../../../hooks/useIsMobile';

const Reviews = () => {
  const theme = useTheme();
  const mobile = useIsMobile();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <Grid
        container
        direction="column"
        alignItems="center"
        spacing={4}
        sx={{
          maxWidth: 'lg',
          px: 2,
        }}
      >
        <Grid>
          <Typography variant="h4" fontWeight={700} align="center">
            Real Sellers, Real Results
          </Typography>
        </Grid>
        <Grid xs={12}>
          <Card sx={{ width: '100%', maxWidth: 'sm', p: 2 }}>
            <Grid container spacing={2}>
              <Grid>
                <img
                  src="/mulberry.jpg"
                  style={{
                    width: mobile ? '100%' : 200,
                    borderRadius: 10,
                  }}
                />
              </Grid>
              <Grid xs>
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  height="100%"
                >
                  <Grid>
                    <Typography variant="h6">Fairhope, AL</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h6" fontWeight={400}>
                      <span
                        style={{
                          fontWeight: 700,
                          color: theme.palette.success.main,
                        }}
                      >
                        Saved
                      </span>{' '}
                      <b>over $15,000</b> compared to average commissions.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reviews;
