/* eslint-disable max-len */
import { Status } from '../types/Status';

export const mapStatus = (
  needed: boolean,
  completed: boolean,
  pending: boolean
) => {
  if (needed) {
    return 'needed';
  }
  if (completed) {
    return 'completed';
  }
  if (pending) {
    return 'pending';
  }
  return 'on hold';
};

export const TRANSACTION_STEPS = [
  'showing',
  'offer',
  'inspection',
  'appraisal',
  'title',
  'financing',
  'closing',
] as const;

export const STEP_LABELS: Record<(typeof TRANSACTION_STEPS)[number], string> = {
  showing: 'Showings',
  offer: 'Offers',
  inspection: 'Inspections',
  appraisal: 'Appraisal',
  title: 'Title',
  financing: 'Financing',
  closing: 'Closing',
};

export const getStepStatusAndInfo = (
  step: (typeof TRANSACTION_STEPS)[number],
  needed: boolean,
  completed: boolean,
  pending: boolean
): { status: string; statusText: string } => {
  let status: 'needed' | 'completed' | 'pending' | 'on hold';

  if (needed) {
    status = 'needed';
  } else if (completed) {
    status = 'completed';
  } else if (pending) {
    status = 'pending';
  } else {
    status = 'on hold';
  }

  return { statusText: STEP_EDUCATIONAL_INFO[step][status], status };
};

export const mapStatusToProgressBar = (
  step: (typeof TRANSACTION_STEPS)[number],
  status: Status
) => {
  switch (step) {
    case 'showing':
      return getStepStatusAndInfo(
        step,
        false,
        status.showing.completed,
        status.showing.data.length > 0
      );
    case 'offer':
      return getStepStatusAndInfo(
        step,
        false,
        status.offer.completed,
        status.offer.data.length > 0
      );
    case 'inspection':
      return getStepStatusAndInfo(
        step,
        status.inspection.needed,
        status.inspection.completed,
        status.inspection.pending
      );
    case 'appraisal':
      return getStepStatusAndInfo(
        step,
        status.appraisal.needed,
        status.appraisal.completed,
        status.appraisal.pending
      );
    case 'financing':
      return getStepStatusAndInfo(
        step,
        status.financing.needed,
        status.financing.completed,
        status.financing.pending
      );
    case 'title':
      return getStepStatusAndInfo(
        step,
        status.title.needed,
        status.title.completed,
        status.title.pending
      );
    case 'closing':
      return getStepStatusAndInfo(
        step,
        status.closing.needed,
        status.closing.completed,
        status.closing.pending
      );
  }
};

type StepEducationalInfo = {
  [K in keyof typeof STEP_LABELS]: {
    needed: string;
    completed: string;
    pending: string;
    'on hold': string;
  };
};

export const STEP_EDUCATIONAL_INFO: StepEducationalInfo = {
  showing: {
    needed:
      'No showings have been scheduled yet. Keep your home ready for viewing requests.',
    completed: 'You have an accepted offer and showings are complete.',
    pending:
      'Showings are still happening. Keep your home ready for when buyers express interest.',
    'on hold':
      'No current showing requests. Keep your home ready for when buyers express interest.',
  },
  offer: {
    needed:
      "No offers have been submitted yet. Continue maintaining your home's showing condition.",
    completed:
      "You've accepted an offer! We'll now proceed with the buyer's due diligence steps.",
    pending:
      'You have received an offer. Review it with your agent to make your decision.',
    'on hold':
      'No active offers at this time. Consider feedback from previous showings if available.',
  },
  inspection: {
    needed:
      'The buyer needs to schedule their home inspection. They typically have 7-10 days to complete this.',
    completed:
      'The inspection is complete. Await any repair requests or negotiations from the buyer.',
    pending:
      'Inspections are in progress. Please ensure all utilities and equipment are on. After inspections, the buyer may request repairs.',
    'on hold':
      "Inspection period hasn't started yet. This will begin once you accept an offer.",
  },
  appraisal: {
    needed:
      "The buyer's lender requires an appraisal. They will schedule this directly.",
    completed:
      "Appraisal is complete. The results have been sent to the buyer's lender.",
    pending:
      'Appraisal is scheduled. Please ensure the property is accessible at the arranged time.',
    'on hold':
      "Appraisal hasn't been scheduled yet. This typically occurs after the inspection period.",
  },
  title: {
    needed:
      "Title search needs to be conducted. The title company will research your property's history.",
    completed:
      'Title search is complete and clear. We can proceed with the next steps.',
    pending:
      'Title company is conducting their search. They may contact you if they need additional information.',
    'on hold':
      "Title work hasn't begun yet. This typically occurs after the inspection period.",
  },
  financing: {
    needed:
      'Buyer needs to complete their mortgage approval process. This may take 2-3 weeks.',
    completed:
      "Buyer's financing is approved! We're clear to move toward closing.",
    pending:
      'Buyer is working with their lender to finalize financing. The lender may request an appraisal.',
    'on hold':
      "Buyer's financing process hasn't started yet. This begins after offer acceptance.",
  },
  closing: {
    needed:
      'Time to schedule the closing. Your agent will coordinate with all parties to set a date.',
    completed:
      'Congratulations! The sale has closed and funds have been transferred.',
    pending:
      'Closing is scheduled. Prepare for the final walkthrough and document signing.',
    'on hold':
      "We're not ready for closing yet. Other steps need to be completed first.",
  },
};
