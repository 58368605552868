import { Chip, ChipProps } from '@mui/material';
import { DocType } from '@prisma/client';

interface FileTypeChipProps extends Omit<ChipProps, 'color'> {
  docType: DocType;
}

const getChipColor = (docType: DocType): ChipProps['color'] => {
  switch (docType) {
    case DocType.PURCHASE_AGREEMENT:
      return 'secondary'; // Purple
    case DocType.INSPECTION_ADDENDUM:
      return 'info'; // Light Blue
    case DocType.PRE_APPROVAL:
      return 'success'; // Green
    case DocType.PROOF_OF_FUNDS:
      return 'warning'; // Orange
    case DocType.REPAIR_RECEIPT:
      return 'primary'; // Blue
    case DocType.OTHER:
      return 'default'; // Grey
    default:
      return 'default';
  }
};

const normalizeEnumLabel = (enumValue: string): string => {
  return enumValue
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const FileTypeChip = ({ docType, ...props }: FileTypeChipProps) => {
  const chipColor = getChipColor(docType);

  return (
    <Chip
      label={normalizeEnumLabel(docType)}
      color={chipColor}
      size="small"
      variant="filled"
      sx={{
        fontWeight: 600,
        '&.MuiChip-filled': {
          color: (theme) =>
            chipColor === 'warning' || chipColor === 'default'
              ? theme.palette.grey[900]
              : '#fff',
        },
      }}
      {...props}
    />
  );
};
