/* eslint-disable max-len */
const SquareArea = ({
  color = 'none',
  size,
  sx,
}: {
  color?: string;
  size: number;
  sx?: any;
}) => (
  <span style={sx}>
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 20"
      fill={'#fff'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1.0" clipPath="url(#clip0_358_11049)">
        <path
          d="M10.5979 18.6528L4.14766 12.2026C3.41758 11.4725 3.41758 10.1283 4.14766 9.39817L10.5979 2.94796C11.328 2.21787 12.6722 2.21787 13.4023 2.94796L19.8525 9.39817C20.5826 10.1283 20.5826 11.4725 19.8525 12.2026L13.4023 18.6528C12.6722 19.3829 11.328 19.3829 10.5979 18.6528V18.6528Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.3999 15.8057L7.63636 21.0421"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3633 21.0421L21.5997 15.8057"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_358_11049">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
);

export default SquareArea;
