import { Alert, Box, Card, Slider, Stack, Typography } from '@mui/material';
import { listings } from '@prisma/client';
import { formatCurrency } from '../../utils/formatCurrency';
import { useEffect, useState } from 'react';

interface Props {
  listing: listings;
  offerPrice: number;
  handlePriceChange: (price: number) => void;
}

const BuyingPower: React.FC<Props> = ({
  listing,
  offerPrice,
  handlePriceChange,
}) => {
  const homepointFee = Number(import.meta.env.VITE_HOMEPOINT_FEE);
  const buyingPower =
    listing.price -
    listing.price * ((100 - listing.commission) / 100) -
    homepointFee * listing.price;
  const [morePower, setMorePower] = useState(buyingPower);
  const diff = buyingPower - morePower;

  useEffect(() => {
    handlePriceChange(listing.price - diff);
  }, [morePower]);

  return (
    <Card elevation={2} sx={{ marginTop: 1 }}>
      <Alert severity="info">
        Most home prices have average commissions built into the price.
      </Alert>
      <Box p={2}>
        <Typography variant="body2">
          Homepoint will verify if the seller is offering a buyer&apos;s agent
          commission. Since you are using Homepoint, our flat fee is typically
          much lower than the offered commission. You can use this to your
          advantage as your <b>buying power</b>.
        </Typography>
        <Typography variant="body1" textAlign={'center'} fontWeight={600}>
          Buying Power: {formatCurrency(buyingPower)}
        </Typography>
        <Stack
          spacing={2}
          direction="row"
          sx={{ alignItems: 'center', mb: 1, p: 2 }}
        >
          <Typography variant="body2">More Savings</Typography>
          <Slider
            min={0}
            max={buyingPower}
            value={morePower}
            step={1000}
            color="success"
            onChange={(event: any) =>
              setMorePower(
                event.target?.value === '' ? 0 : Number(event.target?.value)
              )
            }
            sx={{ width: '100%', maxWidth: 500 }}
          />
          <Typography variant="body2">More Competitive</Typography>
        </Stack>
        <Typography variant="body2" textAlign={'center'}>
          Your offer price of {formatCurrency(offerPrice)} is{' '}
          {formatCurrency(morePower)} better than offers with{' '}
          {listing.commission}% commissions.
        </Typography>
      </Box>
    </Card>
  );
};

export default BuyingPower;
