import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../routes/app';
import { useAuth } from '../../contexts/AuthContext';
import MainButtons from './toolbar/MainButtons';
import { HomeOutlined, LogoutOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useEffect } from 'react';
import { MenuListItem } from './MenuListItem';
import Detail from '../../pages/Dashboard/Selling/Buyers/Detail/Detail';

const drawerWidth = 240;

const openedMixin = (theme: Theme, isMobile: boolean): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme, isMobile: boolean): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: isMobile ? 0 : `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 3,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: '#ffffff',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: theme.zIndex.drawer + 2,
    ...(!isMobile && {
      '&:hover': {
        ...openedMixin(theme, isMobile),
        '& .MuiDrawer-paper': openedMixin(theme, isMobile),
      },
    }),
    ...(open && {
      ...openedMixin(theme, isMobile),
      '& .MuiDrawer-paper': openedMixin(theme, isMobile),
    }),
    ...(!open && {
      ...closedMixin(theme, isMobile),
      '& .MuiDrawer-paper': closedMixin(theme, isMobile),
    }),
    ...(open &&
      isMobile && {
        ...openedMixin(theme, isMobile),
        position: 'absolute',
      }),
    ...(!open &&
      isMobile && {
        transition: undefined,
      }),
  };
});

const MiniDrawer: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(false);
  const { isAuthenticated, logout } = useAuth();
  const authLoading = useSelector((state: RootState) => state.auth.loading);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const currentAccountType = currentUser?.accountType;

  useEffect(() => {
    setOpen(false);
  }, [location]);

  const hidden = !isAuthenticated;
  const mobileOffset = isMobile ? 50 : 0;
  const authOffset = isAuthenticated ? 65 - mobileOffset : 0;

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#f6f9fb',
        minHeight: '100vh',
        paddingBottom: theme.spacing(4),
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          opacity: 0.97,
          backgroundColor: isAuthenticated
            ? theme.palette.primary.main
            : undefined,
        }}
      >
        <Toolbar>
          {!isAuthenticated ? (
            <Stack
              direction="row"
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ width: '100%' }}
            >
              <Link to="/">
                <img
                  src="/new_logo_light.png"
                  width={200}
                  style={{ marginTop: '8px' }}
                />
              </Link>
              <Button
                variant="contained"
                size="small"
                color="highlight"
                sx={{
                  fontWeight: 600,
                  height: 40,
                }}
                onClick={() => navigate('/')}
              >
                Learn More
              </Button>
            </Stack>
          ) : (
            <MainButtons toggleMenu={() => setOpen(!open)} />
          )}
        </Toolbar>
      </AppBar>
      {!hidden && (
        <Drawer variant="permanent" open={open} sx={{ boxShadow: 4 }}>
          <DrawerHeader />
          <List sx={{ mt: 0 }}>
            {/* PBUYER ONLY home button */}
            {currentUser?.type === 'PBUYER' && (
              <>
                <MenuListItem
                  route={{
                    key: 'pbuyer-home-route',
                    title: 'Home',
                    path: `/buyers/${currentUser?.id}`,
                    enabled: true,
                    component: Detail,
                    icon: HomeOutlined,
                    menu: true,
                    protected: true,
                  }}
                  currentAccountType={currentAccountType}
                />
                <Divider sx={{ opacity: 0.4 }} />
              </>
            )}
            {routes
              .filter((route) => route.menu)
              .filter((route) => {
                if (route.sellerOnly) {
                  return (
                    (currentUser?.type === 'SELLER' ||
                      currentUser?.type === 'BUYER') &&
                    route.sellerOnly
                  );
                }
                if (route.adminOnly) {
                  return currentUser?.type === 'ADMIN' && route.adminOnly;
                }
                return true;
              })
              .map((route, index) => (
                <React.Fragment key={`menu-${index}`}>
                  <MenuListItem
                    route={route}
                    currentAccountType={currentAccountType}
                  />
                  <Divider sx={{ opacity: 0.4 }} />
                </React.Fragment>
              ))}
            <ListItem
              sx={{
                '&:hover': {
                  backgroundColor: 'action.hover',
                  cursor: 'pointer',
                },
              }}
              onClick={() => {
                logout();
              }}
            >
              <ListItemIcon>
                <LogoutOutlined />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" fontWeight={500}>
                  Log Out
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Drawer>
      )}
      {authLoading && <CircularProgress />}
      <Container
        maxWidth="lg"
        sx={{ paddingTop: 10, width: `calc(100vw - ${authOffset}px)`, pb: 2 }}
      >
        <Outlet />
      </Container>
    </Box>
  );
};

export default MiniDrawer;
