import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import {
  AppraiseStatus,
  FinancingStatus,
  TitleStatus,
  InspectionStatus,
  ClosingStatus,
  listings,
  offers,
  transactions,
  Prisma,
} from '@prisma/client';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import transactionsService from '../../api/services/transactions';

interface AddTransactionModalProps {
  listing: listings;
  offer: offers;
}

const validationSchema = Yup.object({
  lenderEmail: Yup.string().email('Invalid email').nullable(),
  appraiserEmail: Yup.string().email('Invalid email').nullable(),
  appraiserPhone: Yup.string().nullable(),
  appraisal: Yup.string().oneOf(Object.values(AppraiseStatus)).required(),
  financing: Yup.string().oneOf(Object.values(FinancingStatus)).required(),
  title: Yup.string().oneOf(Object.values(TitleStatus)).required(),
  inspection: Yup.string().oneOf(Object.values(InspectionStatus)).required(),
  closing: Yup.string().oneOf(Object.values(ClosingStatus)).required(),
  otherPartyEmail: Yup.string().email('Invalid email').required(),
  otherPartyPhone: Yup.string().required(),
});

const AddTransactionModal = NiceModal.create<AddTransactionModalProps>(
  ({ listing, offer }) => {
    const modal = useModal();

    const formik = useFormik({
      initialValues: {
        lenderEmail: null,
        appraiserEmail: null,
        appraiserPhone: null,
        appraisal: 'NOT_NEEDED',
        financing: 'NOT_NEEDED',
        title: 'ORDERED',
        inspection: 'NOT_NEEDED',
        closing: 'SCHEDULED',
        otherPartyEmail: '',
        otherPartyPhone: '',
      } as Omit<
        Prisma.transactionsCreateInput,
        'id' | 'userId' | 'offerId' | 'listingsId' | 'createdAt' | 'updatedAt'
      >,
      validationSchema,
      onSubmit: async (values) => {
        try {
          await transactionsService.create({
            ...values,
            appraisal: values.appraisal,
            financing: values.financing,
            title: values.title,
            inspection: values.inspection,
            closing: values.closing,
            offer: {
              connect: {
                id: offer.id,
              },
            },
            listings: {
              connect: {
                id: listing.id,
              },
            },
            user: {
              connect: {
                id: listing.userId,
              },
            },
          });
          modal.remove();
        } catch (error) {
          console.error('Failed to create transaction:', error);
        }
      },
    });

    return (
      <Dialog
        open={true}
        onClose={() => modal.remove()}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add Transaction</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                fullWidth
                id="lenderEmail"
                name="lenderEmail"
                label="Lender Email"
                type="email"
                value={formik.values.lenderEmail || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.lenderEmail &&
                  Boolean(formik.errors.lenderEmail)
                }
                helperText={
                  formik.touched.lenderEmail && formik.errors.lenderEmail
                }
              />

              <TextField
                fullWidth
                id="appraiserEmail"
                name="appraiserEmail"
                label="Appraiser Email"
                type="email"
                value={formik.values.appraiserEmail || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.appraiserEmail &&
                  Boolean(formik.errors.appraiserEmail)
                }
                helperText={
                  formik.touched.appraiserEmail && formik.errors.appraiserEmail
                }
              />

              <TextField
                fullWidth
                id="appraiserPhone"
                name="appraiserPhone"
                label="Appraiser Phone"
                value={formik.values.appraiserPhone || ''}
                onChange={formik.handleChange}
                error={
                  formik.touched.appraiserPhone &&
                  Boolean(formik.errors.appraiserPhone)
                }
                helperText={
                  formik.touched.appraiserPhone && formik.errors.appraiserPhone
                }
              />

              <TextField
                fullWidth
                id="appraisal"
                name="appraisal"
                label="Appraisal Status"
                select
                SelectProps={{ native: true }}
                value={formik.values.appraisal}
                onChange={formik.handleChange}
                error={
                  formik.touched.appraisal && Boolean(formik.errors.appraisal)
                }
                helperText={formik.touched.appraisal && formik.errors.appraisal}
              >
                {Object.values(AppraiseStatus).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>

              <TextField
                fullWidth
                id="financing"
                name="financing"
                label="Financing Status"
                select
                SelectProps={{ native: true }}
                value={formik.values.financing}
                onChange={formik.handleChange}
                error={
                  formik.touched.financing && Boolean(formik.errors.financing)
                }
                helperText={formik.touched.financing && formik.errors.financing}
              >
                {Object.values(FinancingStatus).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>

              <TextField
                fullWidth
                id="title"
                name="title"
                label="Title Status"
                select
                SelectProps={{ native: true }}
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
              >
                {Object.values(TitleStatus).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>

              <TextField
                fullWidth
                id="inspection"
                name="inspection"
                label="Inspection Status"
                select
                SelectProps={{ native: true }}
                value={formik.values.inspection}
                onChange={formik.handleChange}
                error={
                  formik.touched.inspection && Boolean(formik.errors.inspection)
                }
                helperText={
                  formik.touched.inspection && formik.errors.inspection
                }
              >
                {Object.values(InspectionStatus).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>

              <TextField
                fullWidth
                id="closing"
                name="closing"
                label="Closing Status"
                select
                SelectProps={{ native: true }}
                value={formik.values.closing}
                onChange={formik.handleChange}
                error={formik.touched.closing && Boolean(formik.errors.closing)}
                helperText={formik.touched.closing && formik.errors.closing}
              >
                {Object.values(ClosingStatus).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>

              <TextField
                fullWidth
                id="otherPartyEmail"
                name="otherPartyEmail"
                label="Other Party Email"
                type="email"
                value={formik.values.otherPartyEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.otherPartyEmail &&
                  Boolean(formik.errors.otherPartyEmail)
                }
                helperText={
                  formik.touched.otherPartyEmail &&
                  formik.errors.otherPartyEmail
                }
              />

              <TextField
                fullWidth
                id="otherPartyPhone"
                name="otherPartyPhone"
                label="Other Party Phone"
                value={formik.values.otherPartyPhone}
                onChange={formik.handleChange}
                error={
                  formik.touched.otherPartyPhone &&
                  Boolean(formik.errors.otherPartyPhone)
                }
                helperText={
                  formik.touched.otherPartyPhone &&
                  formik.errors.otherPartyPhone
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => modal.remove()}>Cancel</Button>
            <Button type="submit" variant="contained" color="primary">
              Add Transaction
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);

export default AddTransactionModal;
