import { Prisma } from '@prisma/client';
import axiosInstance from '../api';

const transactionsService = {
  find: async () => {
    const res = await axiosInstance.get('/transactions');
    return res.data;
  },
  create: async (data: Prisma.transactionsCreateInput) => {
    const res = await axiosInstance.post('/transactions', data);
    return res.data;
  },
  patch: async (id: string, data: Prisma.transactionsUpdateInput) => {
    const res = await axiosInstance.patch(`/transactions/${id}`, data);
    return res.data;
  },
};

export default transactionsService;
