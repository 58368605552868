/* eslint-disable max-len */
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import useIsMobile from '../../../hooks/useIsMobile';

const Featured: React.FC = () => {
  const isMobile = useIsMobile();

  return (
    <Stack direction={'column'} alignItems={'center'} spacing={2}>
      <Typography variant="h5" fontWeight={700} align="center">
        Featured in
      </Typography>
      <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
        <a
          href="https://bhamnow.com/2024/09/06/birmingham-couple-new-way-to-real-estate/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://i0.wp.com/bhamnow.com/wp-content/uploads/2016/07/Header-Plain-e1470599322158.png?fit=1200%2C280&quality=80&ssl=1"
            width={200}
            style={{
              filter: 'grayscale(100%)',
            }}
          />
        </a>
        <a
          href="https://www.bizjournals.com/birmingham/news/2024/12/02/local-cre-and-tech-experts-launch-startup.html?cx_testId=40&cx_testVariant=cx_20&cx_artPos=3#cxrecs_s"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://assets.bizjournals.com/static/img/logos/birmingham-logo.svg"
            width={200}
            style={{
              filter: 'brightness(0.5) saturate(0)',
              opacity: 0.7,
            }}
          />
        </a>
      </Stack>
    </Stack>
  );
};

export default Featured;
