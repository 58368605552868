import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { Phone } from '@mui/icons-material';
import { useState } from 'react';

const TextOrCall = ({ inverted = false }: { inverted?: boolean }) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const supportsTel = () => {
    const a = document.createElement('a');
    a.href = 'tel:+12059730007';
    return a.protocol === 'tel:';
  };

  const renderContactButton = () => {
    const linkStyles = {
      color: 'inherit',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    };

    const buttonStyles = {
      backgroundColor: inverted ? 'white' : 'primary.main',
      color: inverted ? 'primary.main' : 'white',
      borderRadius: '20px',
      padding: '8px 20px',
      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
      cursor: 'pointer',
      transition: 'transform 0.2s ease-in-out',
      border: inverted ? '1px solid' : 'none',
      borderColor: 'primary.main',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
      },
    };

    if (supportsTel()) {
      return (
        <Stack
          direction="row"
          alignItems="center"
          spacing={0.5}
          sx={buttonStyles}
        >
          <Phone sx={{ fontSize: '1.2rem' }} />
          <Typography fontSize="0.9rem" fontWeight={600}>
            <Box component="a" href="sms:+12059730007" sx={linkStyles}>
              Text
            </Box>
            {' or '}
            <Box component="a" href="tel:+12059730007" sx={linkStyles}>
              Call Us
            </Box>
          </Typography>
        </Stack>
      );
    }

    return (
      <Stack
        onClick={() => setDialogOpen(true)}
        direction="row"
        alignItems="center"
        spacing={0.5}
        sx={buttonStyles}
      >
        <Phone sx={{ fontSize: '1.2rem' }} />
        <Typography fontSize="0.9rem" fontWeight={600}>
          Text or Call Us
        </Typography>
      </Stack>
    );
  };

  return (
    <>
      {renderContactButton()}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <Stack spacing={2} py={1}>
            <Button
              variant="contained"
              href="tel:+12059730007"
              startIcon={<Phone />}
            >
              Call (205) 973-0007
            </Button>
            <Button
              variant="contained"
              href="sms:+12059730007"
              startIcon={<Phone />}
            >
              Text (205) 973-0007
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TextOrCall;
