/* eslint-disable max-len */
import React from 'react';
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  SvgIconProps,
} from '@mui/material';
import SavingsIcon from '@mui/icons-material/Savings';
import BusinessIcon from '@mui/icons-material/Business';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InsightsIcon from '@mui/icons-material/Insights';
import useIsMobile from '../../../hooks/useIsMobile';

interface FeatureCardProps {
  icon: React.ComponentType<SvgIconProps>;
  title: string;
  description: string;
}

const sellerFeatureCards: FeatureCardProps[] = [
  {
    icon: BusinessIcon,
    title: 'Sell Like a Pro',
    description:
      'Get **MLS exposure**, **pro marketing** & **pricing opinions**.',
  },
  {
    icon: InsightsIcon,
    title: 'Innovative Technology',
    description:
      'Our platform streamlines the transaction, making it **simpler**, **faster**, and **smoother**.',
  },
  {
    icon: VerifiedUserIcon,
    title: "You're in Control",
    description: 'Manage your sale with **full agent support** when needed.',
  },
  {
    icon: SavingsIcon,
    title: 'Save Thousands',
    description:
      '**Pay just 1%** at closing, not the average **3% commission**.',
  },
];

const buyerFeatureCards: FeatureCardProps[] = [
  {
    icon: SavingsIcon,
    title: 'Save Thousands',
    description:
      'Use the difference of the offered agent commission for **closing costs** or to make a **more competitive offer**.',
  },
  {
    icon: BusinessIcon,
    title: 'Expert Guidance',
    description:
      'We help you craft an expert offer with our **market analysis** and **negotiation support**.',
  },
  {
    icon: InsightsIcon,
    title: 'Innovative Technology',
    description:
      'Our platform streamlines the transaction, making it **simpler**, **faster**, and **smoother**.',
  },
  {
    icon: VerifiedUserIcon,
    title: "You're in Control",
    description:
      'Manage your purchase with **full agent support** when needed.',
  },
];

const FeatureCard = ({ icon: Icon, title, description }: FeatureCardProps) => {
  const mobile = useIsMobile();

  const renderDescription = (text: string) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts.map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return (
          <Typography
            key={index}
            variant="body1"
            component="span"
            fontWeight={600}
          >
            {part.slice(2, -2)}
          </Typography>
        );
      }
      return (
        <Typography key={index} variant="body1" component="span">
          {part}
        </Typography>
      );
    });
  };

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box sx={{ boxShadow: 0, borderRadius: 2, height: mobile ? 175 : 250 }}>
        <CardContent
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            p: 3,
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Icon color="primary" sx={{ fontSize: 40 }} />
            <Typography variant="h6" fontWeight="bold">
              {title}
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            {renderDescription(description)}
          </Box>
        </CardContent>
      </Box>
    </Grid>
  );
};

interface WhyHomepointProps {
  isBuyer?: boolean;
}

const WhyHomepoint = ({ isBuyer = false }: WhyHomepointProps) => {
  const features = isBuyer ? buyerFeatureCards : sellerFeatureCards;

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: 'auto',
        textAlign: 'center',
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        {features.map((card, index) => (
          <FeatureCard key={index} {...card} />
        ))}
      </Grid>
    </Box>
  );
};

export default WhyHomepoint;
