import React, { useEffect } from 'react';
import {
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container id="faq" maxWidth="lg">
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, marginBottom: 4, textAlign: 'center' }}
      >
        FAQs
      </Typography>
      <Typography variant="h5">General</Typography>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What is Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint is a real estate platform and service that combines
            technology with expert help to make buying or selling a home simple
            and affordable. You get tools to guide you and access to
            professional agents when you need them. It&apos;s all for 1% of the
            home price paid at closing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How is Homepoint different from a traditional real estate agent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint combines advanced technology with expert support to create
            a smoother and more transparent process. The platform simplifies
            each step with easy-to-use tools, while our experienced agents are
            available to guide you whenever needed. Instead of paying a
            percentage-based commission, you pay a flat fee, saving you money
            without sacrificing personalized service.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            Can I get help from a real estate agent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Yes! A licensed agent is always ready to assist you at any point in
            the process.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Typography variant="h5">Selling</Typography>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How does selling with Homepoint work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Start by listing your home on our platform. You set the price (or we
            can suggest one), and we&apos;ll market your home. Offers, messages,
            and showings are organized in a dashboard. Once you accept an offer,
            we handle the paperwork and guide you to closing.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What does it cost to sell with Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You pay 1% of the home price at closing, which is much less than
            average commissions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How can Homepoint help me price my home?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We provide tools and market data to help you set a competitive
            price. An agent can also suggest a price if you need help.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            Who handles negotiations?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our platform lets you compare offers easily. If you need help, an
            agent is available to guide you through counteroffers and terms.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <br />
      <Typography variant="h5">Buying</Typography>
      <br />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How does buying with Homepoint work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You can search for homes, schedule tours, and make offers using our
            platform. If you need help, an agent is there to guide you.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What support do I get when buying a home?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Our agents can help with offers, negotiations, and paperwork
            whenever you need them.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            How much does it cost to buy with Homepoint?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The flat fee of $5,000 is typically paid by the seller, just like
            traditional agent fees. This includes all the support you&apos;ll
            need.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            Why does it cost money to tour homes?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Homepoint utilizes a network of agents to allow you to tour homes
            without that agent taking any commission. This fee covers the cost
            of that agent&apos;s time.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body1" fontWeight={600}>
            What if I already have an agent?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If you&apos;re working with your own agent, you can continue with
            them. If not, we&apos;re happy to help with any listings.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default FAQ;
