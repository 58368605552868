/* eslint-disable max-len */
import { useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useLocation, useNavigate } from 'react-router-dom';
import NewLandingHome from '/new_landing_home.svg';
import SimpleSavings from './components/SimpleSavings';
import WhyHomepoint from './components/WhyHomepoint';
import HIWSellingV2 from './components/HIWV2';
import Featured from './components/Featured';

const LandingV2Buyer = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a hash in the URL
    if (location.hash) {
      const id = location.hash.substring(1);
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container alignItems={'center'} mb={-5} mt={mobile ? 0 : -4}>
        <Grid xs={12} md={7}>
          <Stack direction={'column'} spacing={2}>
            <Typography
              variant={mobile ? 'h4' : 'h3'}
              fontWeight={700}
              textAlign={mobile ? 'center' : 'left'}
            >
              Buy Your Dream Home. Get More for Your Money.
            </Typography>
            <Typography
              variant={'h6'}
              fontWeight={500}
              textAlign={mobile ? 'center' : 'left'}
            >
              Schedule tours, make offers, and get expert support—all for a
              simple flat fee of $5,000 at closing. Our platform puts you in
              control while keeping more money in your pocket.
            </Typography>
            <Button
              variant="contained"
              color="highlight"
              size="large"
              onClick={() => navigate('/signup?type=BUYER')}
              sx={{
                fontWeight: 600,
                fontSize: 20,
                maxWidth: mobile ? '100%' : '300px',
              }}
            >
              Start for Free
            </Button>
          </Stack>
        </Grid>
        <Grid xs={12} md={5}>
          <img src={NewLandingHome} alt="Homepoint" width={'100%'} />
        </Grid>
        <Grid xs={12} display={'flex'} justifyContent={'center'} mb={4} mt={-2}>
          <Featured />
        </Grid>
      </Grid>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <SimpleSavings buyer={true} />
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <WhyHomepoint isBuyer={true} />
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <HIWSellingV2 type="buyer" />
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{ margin: 4 }}
      >
        <Typography variant="h4" fontWeight={700} align="center">
          Ready to start your home search?
        </Typography>
        <Typography variant="body1" fontWeight={500} align="center">
          Sign up today for a better home buying experience.
        </Typography>
        <Button
          variant="contained"
          color="highlight"
          size="large"
          onClick={() => navigate('/signup?type=BUYER')}
          sx={{
            fontWeight: 600,
            fontSize: 20,
            maxWidth: '300px',
          }}
        >
          Start for Free
        </Button>
      </Stack>
    </>
  );
};

export default LandingV2Buyer;
