import { Button, Stack, Typography } from '@mui/material';
import { listings, offers } from '@prisma/client';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { differenceInCalendarDays } from 'date-fns';
import { formatCurrency } from '../../../../../utils/formatCurrency';
import { capitalize } from 'lodash';
import NiceModal from '@ebay/nice-modal-react';
import { useAppDispatch } from '../../../../../store/store';
import { fetchOffers } from '../../../../../store/slices/offers';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../contexts/AuthContext';
import OfferModal from '../../../../../components/OfferModal/OfferModal';
import Proceeds from '../../../../Offers/Proceeds';
// eslint-disable-next-line max-len
import { OfferActions } from '../../../../../components/OfferActions/OfferActions';

interface Props {
  offer?: offers;
  listing: listings;
}

const Offer: React.FC<Props> = ({ offer, listing }) => {
  const dispatch = useAppDispatch();
  const dispatchOffers = () => {
    dispatch(fetchOffers());
  };
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useAuth();
  const isBuyer = currentUser?.id === id;
  const isSeller = currentUser?.id === listing.userId;

  if (!offer) {
    return (
      <Stack direction="column" spacing={1}>
        <Typography variant="h6">Offer</Typography>
        {!isBuyer ? (
          <Typography>Buyer has not submitted an offer</Typography>
        ) : (
          <Button
            variant="contained"
            color="success"
            sx={{ width: 'fit-content' }}
            onClick={() => {
              NiceModal.show(OfferModal, {
                listing,
              });
            }}
          >
            Make an Offer
          </Button>
        )}
      </Stack>
    );
  }

  const { closingDate } = offer;
  const daysToClose = differenceInCalendarDays(
    new Date(),
    new Date(closingDate)
  );

  return (
    <>
      <Grid2 container spacing={1}>
        <Grid2 xs={2}>
          <Typography variant="h6">Offer</Typography>
        </Grid2>
        <Grid2 xs={10} alignContent={'center'}>
          <Typography variant="body2" textAlign={'right'}>
            <b>Submitted</b>:{' '}
            {new Date(offer.createdAt).toLocaleString(undefined, {
              dateStyle: 'medium',
              timeStyle: 'short',
            })}
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Price
          </Typography>
          <Typography variant="body1">{formatCurrency(offer.price)}</Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Payment Method
          </Typography>
          <Typography variant="body1">
            {capitalize(offer.paymentMethod)}
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Closing Date
          </Typography>
          <Typography variant="body1">
            {new Date(offer.closingDate).toLocaleDateString()}
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Closing In
          </Typography>
          <Typography variant="body1">{Math.abs(daysToClose)} days</Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Inspection Required
          </Typography>
          <Typography variant="body1">
            {offer.inspectionPeriod !== null
              ? `Yes, ${offer.inspectionPeriod} day window`
              : 'No'}
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Earnest Money
          </Typography>
          <Typography variant="body1">
            {formatCurrency(offer.earnestMoney)}
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Contingent
          </Typography>
          <Typography variant="body1">
            {offer.contingent ? 'Yes' : 'No'}
          </Typography>
        </Grid2>
        <Grid2 xs={12} sm={3}>
          <Typography variant="body1" fontWeight={700}>
            Buyer&apos;s Commissions
          </Typography>
          <Typography variant="body1">{`${offer.bagentComm}%`}</Typography>
        </Grid2>
        <Grid2 xs={12} sm={12}>
          <Typography variant="body1" fontWeight={700}>
            Additional Terms
          </Typography>
          <Typography variant="body1">
            {offer.additionalTerms === null ? 'None' : offer.additionalTerms}
          </Typography>
        </Grid2>
        <Grid2 xs={12}>
          <Typography variant="body1" fontWeight={700}>
            Expires On
          </Typography>
          <Typography variant="body1">
            {new Date(offer.expiresOn).toLocaleDateString()}
          </Typography>
        </Grid2>
        {isSeller && (
          <Grid2 xs={12} sm={4}>
            <Typography variant="body1" fontWeight={700}>
              Estimated Proceeds
            </Typography>
            <Typography variant="body1">
              <Proceeds listingId={listing.id} offerId={offer.id} />
            </Typography>
          </Grid2>
        )}
        {isSeller && offer.status === 'PENDING' && (
          <Grid2 xs={12} pt={2}>
            <OfferActions
              offer={offer}
              onOfferAccepted={() => {
                dispatchOffers();
              }}
              onActionComplete={() => {
                dispatchOffers();
              }}
            />
          </Grid2>
        )}
        {offer.status !== 'PENDING' && (
          <Grid2 xs={12} sm={isSeller ? 4 : 3}>
            <Typography variant="body1" fontWeight={700}>
              Status
            </Typography>
            <Typography variant="body1">{capitalize(offer.status)}</Typography>
          </Grid2>
        )}
        {offer.status === 'COUNTERED' && (
          <>
            <Grid2 xs={12} sm={isSeller ? 4 : 9}>
              <Typography variant="body1" fontWeight={700}>
                Counter Details
              </Typography>
              <Typography variant="body1">{offer.counter}</Typography>
            </Grid2>
            {!isSeller && (
              <Grid2 xs={12}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ width: 'fit-content' }}
                  onClick={() => {
                    NiceModal.show(OfferModal, {
                      listing,
                    });
                  }}
                >
                  Make a new offer
                </Button>
              </Grid2>
            )}
          </>
        )}
      </Grid2>
    </>
  );
};

export default Offer;
