import { Typography, Box, Stack, TextField, Divider } from '@mui/material';
import { listings, users } from '@prisma/client';
import LoadingButton from '@mui/lab/LoadingButton';
// eslint-disable-next-line max-len
import {
  fetchMessages,
  messagesWithSenderAndReceiver,
} from '../../../../../store/slices/messages';
import { useEffect, useRef, useState } from 'react';
import messagesService from '../../../../../api/services/messages';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../store/store';
import { useSocket } from '../../../../../api/socket';

interface MessageProps {
  text: string;
  isSender: boolean;
  sender: users;
  timestamp: Date;
}

const Message: React.FC<MessageProps> = ({
  text,
  isSender,
  sender,
  timestamp,
}) => {
  const align = isSender ? 'right' : 'left';
  return (
    <Box
      display="flex"
      justifyContent={isSender ? 'flex-end' : 'flex-start'}
      mb={0}
    >
      <Stack
        sx={{
          maxWidth: '75%',
          p: 2,
          wordWrap: 'break-word',
        }}
        direction="column"
      >
        <Stack
          direction="row"
          justifyContent={align === 'left' ? 'flex-start' : 'flex-end'}
          alignItems={'center'}
          spacing={1}
        >
          <Typography variant="body2" fontWeight={600} textAlign={align}>
            {isSender ? sender.firstName : 'You'}
          </Typography>
          <Typography
            variant="caption"
            textAlign={align}
            color={'info.main'}
            fontWeight={600}
          >
            {timestamp.toLocaleString('en-US', {
              month: 'short',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Typography>
        </Stack>
        <Typography variant="body2" textAlign={align}>
          {text}
        </Typography>
      </Stack>
    </Box>
  );
};

interface Props {
  messages: messagesWithSenderAndReceiver;
  listing: listings;
  buyerId: string;
  fullscreen?: boolean;
}

const Messages: React.FC<Props> = ({
  messages,
  listing,
  buyerId,
  fullscreen,
}) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.auth.user) as users;
  const [newMessage, setNewMessage] = useState('');
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const receiverId = user.id === listing.userId ? buyerId : listing.userId;
  const { isConnected } = useSocket();
  const dispatch = useAppDispatch();

  const markMessagesRead = async () => {
    await messagesService.patch({
      where: {
        OR: [
          { senderId: user.id, receiverId },
          { receiverId: user.id, senderId: receiverId },
        ],
      },
      data: {
        unread: false,
      },
    });
  };

  const sendMessage = async (message: string) => {
    setLoading(true);
    try {
      await messagesService.post({
        text: message,
        sender: {
          connect: { id: user?.id },
        },
        receiver: {
          connect: {
            id: receiverId,
          },
        },
        listing: {
          connect: {
            id: listing.id,
          },
        },
      });
      // Remove direct dispatch since socket will trigger update
      setNewMessage('');
      setLoading(false);
      if (!isConnected) {
        dispatch(fetchMessages());
      }
    } catch (e) {
      /* empty */
    }
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
    markMessagesRead();
  }, [messages]);

  return (
    <>
      <Box
        sx={{
          height: fullscreen ? '100%' : '300px',
          overflowY: 'auto',
        }}
        ref={messagesContainerRef}
        id="messages"
      >
        {messages.map((message, index) => {
          return (
            <Message
              key={index}
              text={message.text}
              isSender={message.senderId !== user.id}
              sender={message.sender}
              timestamp={new Date(message.createdAt)}
            />
          );
        })}
      </Box>
      <Stack direction="row" spacing={2} mt={2}>
        <TextField
          fullWidth
          size="small"
          placeholder="Enter your message"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          multiline
          maxRows={4}
        />
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => sendMessage(newMessage)}
          disabled={newMessage.length === 0}
        >
          Send
        </LoadingButton>
      </Stack>
    </>
  );
};

export default Messages;
