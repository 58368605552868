import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uploads } from '@prisma/client';
import uploadsService from '../../api/services/uploads';

interface State {
  uploads: Record<string, uploads[]>;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  uploads: {},
  loading: false,
  error: null,
};

export const fetchUploads = createAsyncThunk(
  'uploads/fetch',
  async (
    params: { listingId: string; userId: string },
    { rejectWithValue }
  ) => {
    try {
      const uploads = await uploadsService.find(
        params.listingId,
        params.userId
      );
      return { id: params.listingId, uploads };
    } catch (error) {
      return rejectWithValue('Failed to fetch uploads');
    }
  }
);

const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUploads.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchUploads.fulfilled,
        (state, action: PayloadAction<{ id: string; uploads: uploads[] }>) => {
          state.loading = false;
          state.uploads[action.payload.id] = action.payload.uploads;
        }
      )
      .addCase(fetchUploads.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default uploadsSlice.reducer;
