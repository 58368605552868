import {
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

const Team = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Grid xs={12} textAlign={'center'}>
        <Typography variant="h4" fontWeight={600}>
          Team
        </Typography>
      </Grid>
      <Grid container xs={12} alignItems={'center'}>
        <Grid xs={12} sm={6} textAlign={'center'}>
          <Stack
            direction={mobile ? 'column' : 'row'}
            spacing={2}
            alignItems={'center'}
          >
            <img
              src="/Caroline_Headshot.jpg"
              style={{
                maxWidth: '250px',
                width: '100%',
                maxHeight: 300,
                borderRadius: 10,
              }}
            />
            <Stack sx={{ maxWidth: '400px', textAlign: 'left', p: 2 }}>
              <Typography variant="h6" fontWeight={600}>
                Caroline McMeans
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                Real Estate
              </Typography>
              <Typography>
                Real estate professional bringing nearly a decade of industry
                expertise to revolutionize the home buying and selling
                experience.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={12} sm={6} textAlign={'center'}>
          <Stack
            direction={mobile ? 'column' : 'row'}
            spacing={2}
            alignItems={'center'}
          >
            <img
              src="/Trey_Headshot.jpeg"
              style={{
                maxWidth: '300px',
                width: '100%',
                maxHeight: 300,
                borderRadius: 10,
              }}
            />
            <Stack sx={{ textAlign: 'left', p: 2 }}>
              <Typography variant="h6" fontWeight={600}>
                Trey McMeans
              </Typography>
              <Typography variant="subtitle1" fontWeight={500}>
                Technology
              </Typography>
              <Typography>
                Seasoned technologist focused on bringing innovation and
                efficiency to the real estate market through modern solutions.
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default Team;
