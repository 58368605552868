import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../../types/Status';
import statusService from '../../api/services/status';

interface State {
  statuses: Record<string, Status>;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  statuses: {},
  loading: false,
  error: null,
};

export const fetchStatus = createAsyncThunk(
  'status/fetch',
  async (id: string, { rejectWithValue }) => {
    try {
      const status = await statusService.get({ id });
      return { id, status };
    } catch (error) {
      return rejectWithValue('Failed to fetch status');
    }
  }
);

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchStatus.fulfilled,
        (state, action: PayloadAction<{ id: string; status: Status }>) => {
          state.loading = false;
          state.statuses[action.payload.id] = action.payload.status;
        }
      )
      .addCase(fetchStatus.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default statusSlice.reducer;
