import { useEffect, useState } from 'react';
import listingsService, {
  listingsWithPricingOpinion,
} from '../../../api/services/listings';
import ListingCard from '../../../components/ListingCard/ListingCard';
import {
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Card,
  Grid,
  Typography,
  Button,
  Divider,
  Box,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
// eslint-disable-next-line max-len
import UpdateDetailsModal from '../../../components/UpdateDetailsModal/UpdateDetailsModal';
import QRCode from '../../QR/QRCode';
import TransactionStatus from '../../Transactions/TransactionStatus';
// eslint-disable-next-line max-len
import AddTransactionModal from '../../../components/AddTransactionModal/AddTransactionModal';
import offersService from '../../../api/services/offers';
import { useSelector } from 'react-redux';
import { selectTransactions } from '../../../store/selectors/transactions';
import { fetchTransactions } from '../../../store/slices/transactions';
import { RootState, useAppDispatch } from '../../../store/store';

const Listings: React.FC = () => {
  const [listings, setListings] = useState<listingsWithPricingOpinion[]>([]);
  const [expandedListing, setExpandedListing] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetchListings = async () => {
    const res = (await listingsService.find({
      params: {
        orderBy: {
          createdAt: 'desc',
        },
        where: {
          type: 'SELLER',
        },
      },
    })) as listingsWithPricingOpinion[];
    setListings(res);
  };

  const transactions = useSelector((state: RootState) =>
    selectTransactions(state)
  );

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  useEffect(() => {
    fetchListings();
  }, []);

  const handleToggleAccordion = (listingId: string) => {
    setExpandedListing(expandedListing === listingId ? null : listingId);
  };

  return (
    <>
      {listings.map((listing) => {
        const transaction = transactions.find(
          (t) => t.listingId === listing.id
        );
        return (
          <Card key={listing.id} sx={{ mb: 2 }}>
            <Stack direction="column" spacing={0}>
              <ListingCard listing={listing} />
              <TransactionStatus transaction={transaction} listing={listing} />
            </Stack>
            <Divider />
            {listing.pricingOpinion && (
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/price-opinion/${listing.pricingOpinion.id}`)
                }
                sx={{ m: 2 }}
              >
                View Price Opinion
              </Button>
            )}
            <Button
              variant="contained"
              sx={{ m: 2 }}
              onClick={() => handleToggleAccordion(listing.id)}
            >
              {expandedListing === listing.id ? 'Hide Details' : 'View Details'}
            </Button>
            <Button
              variant="contained"
              sx={{ m: 2 }}
              onClick={() => NiceModal.show(UpdateDetailsModal, { listing })}
            >
              Send Update Note
            </Button>
            <Button
              variant="contained"
              sx={{ m: 2 }}
              onClick={async () => {
                const offer = await offersService.find({
                  where: {
                    listingId: listing.id,
                  },
                });
                if (offer.length > 0) {
                  NiceModal.show(AddTransactionModal, {
                    listing,
                    offer: offer[0],
                  });
                }
              }}
            >
              Add Transaction
            </Button>
            <Box sx={{ p: 2 }}>
              <QRCode listingId={listing.id} />
            </Box>
            <Accordion
              expanded={expandedListing === listing.id}
              onChange={() => handleToggleAccordion(listing.id)}
            >
              <AccordionSummary>
                <Typography>Listing Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} sx={{ p: 2 }}>
                  {Object.entries(listing).map(
                    ([key, value]) =>
                      key !== 'uploads' &&
                      key !== 'pricingOpinion' && (
                        <Grid item xs={12} sm={6} md={4} key={key}>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            {key}:
                          </Typography>
                          <Typography variant="body2">
                            {typeof value === 'object'
                              ? JSON.stringify(value)
                              : String(value)}
                          </Typography>
                        </Grid>
                      )
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Card>
        );
      })}
    </>
  );
};

export default Listings;
