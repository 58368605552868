import {
  useMediaQuery,
  Grid,
  useTheme,
  Stack,
  Typography,
  Divider,
  Container,
  Button,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import WaitlistForm from './WaitlistForm';
import { useEffect } from 'react';
import Team from './components/Team';

const About = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Grid container gap={2} direction={'column'}>
        <Team />
        <Box p={2}>
          <Grid xs={12} textAlign={'center'}>
            <Typography variant="h4" fontWeight={600}>
              Mission
            </Typography>
          </Grid>
          <Grid xs={12}>
            <Typography variant="h6" fontWeight={600} textAlign={'center'}>
              &#8220;We are not replacing traditional realty services. We are
              providing a new option for home buyers and sellers. Consumers
              deserve another choice that provides affordability, value, and
              expertise.&#8221;
            </Typography>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <Typography variant="body1">
                  Homepoint began with a vision crafted by a dynamic
                  husband-and-wife duo: one a technology enthusiast and the
                  other a seasoned professional in real estate. Their dream: to
                  collaborate and innovate the real estate process. Inspired by
                  the recent NAR settlement, they saw the potential to infuse
                  technology into a market that has remained essentially
                  unchanged for decades.
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'right',
                    fontSize: 24,
                    fontWeight: 500,
                    color: 'primary.main',
                    fontStyle: 'italic',
                  }}
                >
                  Your Home, Your Rules
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <img
                  src="/family_outside_home.jpg"
                  width={'100%'}
                  style={{ borderRadius: 8 }}
                />
              </Grid>
            </Grid>
            <Typography variant="body1">
              In an era when every dollar counts due to high inflation and tight
              finances, the founders identified a way to simplify transactions,
              save money, and empower consumers without disrupting the existing
              real estate ecosystem. Homepoint doesn&apos;t seek to replace real
              estate agents but rather provides a novel choice that puts more
              control in the hands of home sellers and buyers.
            </Typography>
            <br />
            <Typography variant="h6" fontWeight={600} color="primary">
              Why Choose Homepoint?
            </Typography>
            With so many organizations and companies involved in the home-buying
            and selling process, deciding how you want to proceed can be tough.
            However, Homepoint stands out not only for its innovative methods
            but also for its professionals and the value you enjoy when
            utilizing the Homepoint platform.
            <br />
            <br />
            Rest assured, Homepoint is a licensed brokerage with experienced
            real estate agents on staff. We hold the same credentials as any
            traditional real estate firm, ensuring that your transactions are
            managed with the highest professional standards. Our team’s
            expertise ensures that every step of the process—from listing to
            closing—is handled with the utmost care and compliance, offering you
            peace of mind.
            <Typography
              sx={{
                textAlign: 'right',
                fontSize: 24,
                fontWeight: 500,
                color: 'primary.main',
                fontStyle: 'italic',
                lineHeight: '30px',
              }}
            >
              Licensed, Expert Professionals
            </Typography>
            <br />
            {/* Save Money, Gain Flexibility Transform the Buying Process Buyers
              also stand to gain from Homepoint’s innovative model. When you
              purchase a home listed by a Homepoint seller, you benefit from our
              streamlined, cost-effective approach. Easy identity verification,
              seamless home tours, and straightforward offers make the process
              hassle-free. Plus, buyers can leverage these savings by paying
              only a flat fee to negotiate better deals on their future homes.
              Our commitment to financial flexibility means you have more
              resources to make your dream home a reality. */}
            <Typography variant="h6" fontWeight={600}>
              Alabama-Based and Coming to a Neighborhood Near You
            </Typography>
            Currently, Homepoint is proudly licensed in Alabama, but our vision
            doesn’t stop there. If you live in another state and are intrigued
            by what we offer, we want to hear from you! Let us know if
            you&apos;re interested, and we might bring Homepoint to your state
            in the near future. We aim to serve as many communities as possible,
            empowering them in their home-buying and selling experiences!
          </Grid>
        </Box>
      </Grid>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="lg">
        <Stack
          direction="column"
          alignItems={'center'}
          pb={2}
          textAlign={'center'}
          spacing={2}
        >
          <Typography variant="h5" fontWeight={600}>
            Take Control of Your Real Estate Experience
          </Typography>
          Ready to take control of your home buying and selling process? Save
          money, gain flexibility, and enjoy professional support with
          Homepoint.
          <Button
            variant="contained"
            fullWidth={mobile}
            color="highlight"
            sx={{
              minWidth: mobile ? 'auto' : 200, // Ensures button has auto width on mobile, 300px otherwise
              whiteSpace: 'nowrap',
              width: mobile ? '100%' : 300, // Ensures full width on mobile, 300px otherwise
              fontWeight: 700,
            }}
            onClick={() => navigate('/signup')}
          >
            Get Started
          </Button>
        </Stack>
      </Container>
      <Divider sx={{ opacity: 0.5, margin: 4 }} />
      <Container maxWidth="sm">
        <Typography variant="h5" textAlign={'center'} fontWeight={600}>
          Want to keep in touch?
        </Typography>
        <WaitlistForm mobile={mobile} title={'Sign Up'} color="primary" />
      </Container>
    </>
  );
};

export default About;
