/* eslint-disable max-len */
import {
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
  ToggleButtonGroup,
  styled,
  Card,
  Box,
  Container,
  ToggleButton,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import _ from 'lodash';
import { useEffect, useState } from 'react';

interface Section {
  title: string;
  demoUrl?: string;
  content: string[];
  contentPrefix?: string;
  card?: boolean;
}

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  position: 'relative',
  top: theme.spacing(0.375),
  '& .MuiToggleButton-root': {
    borderRadius: theme.spacing(2.5),
    border: `1px solid ${theme.palette.primary.main}`,
    textTransform: 'none',
    padding: theme.spacing(0.5, 2),
    marginRight: theme.spacing(1),
    '&:last-child': {
      marginRight: 0,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
}));

const Product: React.FC = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [type, setType] = useState('Seller');

  const sellerSections = [
    {
      title: 'Create Listing',
      demoUrl: '/create_listing.png',
      content: [
        'Start your listing on your own with our step-by-step guide.',
        'Include general and unique details of your home, included items in the sale.',
        'List any recent renovations to your home.',
        'Homepoint generates a listing description and lets you modify as you see fit.',
        'Choose date for professional photographs.',
        'Let Homepoint price your home or price on your own with our comparison tool.',
      ],
    },
    {
      title: 'Dashboard',
      demoUrl: '/dashboard_screen.png',
      content: [
        'Organizes potential buyers in an easy to view list.',
        'See which buyers have active offers, showings, or messages.',
      ],
    },
    {
      title: 'Offers',
      demoUrl: '/offers_screen.png',
      content: [
        'Offers are displayed for easy comparison.',
        'Homepoint gives offers a score based on closing date, financing type, etc., to help compare offers.',
        'Estimated net proceeds are calculated.',
        'Able to act on each offer by accepting, countering, or declining.',
      ],
    },
    {
      title: 'Buyer Details',
      demoUrl: '/listing_screen.png',
      content: [
        'Schedule showings and submit feedback',
        'Create and submit offers',
        'Upload documents',
        'Send messages for questions, etc.',
      ],
      contentPrefix: 'Buyers can',
      card: true,
    },
  ];

  const buyerSections = [
    {
      title: 'A new way for home buying',
      demoUrl: '/buying_screen.png',
      content: [
        'No commitment, on demand home tours',
        'Prep offers and Homepoint consults on them before submitting to the seller',
        'Support from offer to close with inspections, title, and anything else you need.',
      ],
      card: true,
    },
    {
      title: 'Schedule Tours',
      demoUrl: '/schedule_tour_screen.png',
      content: [
        'Showings are with an agent whose only job is to show you the home, they receive no commission for the showing.',
        'Tours are $50 per showing and these fees are refunded if you close on a home.',
        'Add any home that is for sale and schedule a tour on demand.',
      ],
    },
    {
      title: 'Make Offers',
      demoUrl: '/create_offer_screen.png',
      content: [
        'Create and prep offers with our step-by-step guide.',
        'Homepoint reviews your offer and consults with you on adjustments before sending to the seller.',
        'If your offer is accepted, Homepoint provides support through the transaction process all the way to closing.',
      ],
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderSections = (sections: Section[]) =>
    sections.map((section, index) => (
      <Card
        key={section.title}
        elevation={0}
        sx={{
          mt: 4,
          p: mobile ? 2 : 4,
          backgroundColor:
            index % 2 === 0 ? 'background.default' : 'background.paper',
          borderRadius: 4,
        }}
      >
        <Grid2 container spacing={4} alignItems="center">
          {/* Content Section */}
          <Grid2 xs={12} md={6} order={{ xs: 1, md: index % 2 === 0 ? 1 : 2 }}>
            <Stack spacing={3}>
              <Typography variant="h5" fontWeight={600} color="primary">
                {section.title}
              </Typography>

              <Stack spacing={2}>
                {section.contentPrefix && (
                  <Typography variant="subtitle1" fontWeight={500}>
                    {section.contentPrefix}
                  </Typography>
                )}
                {section.content.map((item, i) => (
                  <Stack
                    key={i}
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        '&::before': {
                          content: '"•"',
                          color: theme.palette.primary.main,
                          fontWeight: 'bold',
                          marginRight: 2,
                        },
                      }}
                    >
                      {item}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          </Grid2>

          {/* Demo Section */}
          {section.demoUrl && (
            <Grid2
              xs={12}
              md={6}
              order={{ xs: 2, md: index % 2 === 0 ? 2 : 1 }}
            >
              <img
                src={section.demoUrl}
                alt={section.title}
                width="100%"
                style={{ borderRadius: 12, boxShadow: theme.shadows[4] }}
              />
            </Grid2>
          )}
        </Grid2>
      </Card>
    ));

  return (
    <Container maxWidth="lg" sx={{ py: 2 }}>
      <Stack spacing={6}>
        <Stack spacing={2} alignItems="center">
          <Typography
            variant="h4"
            textAlign="center"
            sx={{
              fontWeight: 700,
            }}
          >
            How It Works
          </Typography>

          <CustomToggleButtonGroup
            value={type}
            exclusive
            onChange={(_, newValue) => newValue && setType(newValue)}
            aria-label="view type"
          >
            <ToggleButton value="Seller">Selling</ToggleButton>
            <ToggleButton value="Buyer">Buying</ToggleButton>
          </CustomToggleButtonGroup>
        </Stack>

        {type === 'Seller'
          ? renderSections(sellerSections)
          : renderSections(buyerSections)}
      </Stack>
    </Container>
  );
};

export default Product;
