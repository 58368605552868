import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store/store';
import { logout, validate } from '../store/slices/auth';
import { validate as validateApi } from '../api/services/authentication';
import { users } from '@prisma/client';
import { H } from 'highlight.run';
import { fetchOffers } from '../store/slices/offers';
import { fetchMessages } from '../store/slices/messages';
import { fetchShowings } from '../store/slices/showings';
import { AxiosError } from 'axios';

interface AuthContextType {
  isAuthenticated: boolean;
  logout: () => void;
  currentUser: users | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const dispatch = useAppDispatch();
  const isAuthenticated = !!currentUser;

  const logoutDispatch = (message?: string) => {
    dispatch(logout(message));
  };

  const validateApiOnly = async () => {
    try {
      await validateApi();
      await Promise.all([
        dispatch(fetchMessages()),
        dispatch(fetchOffers()),
        dispatch(fetchShowings()),
      ]);
    } catch (error) {
      console.error(error);
      if (
        error instanceof AxiosError &&
        error.response?.data?.message === 'jwt expired'
      ) {
        logoutDispatch('Your session has expired. Please login again.');
      }
    }
  };

  const validateToken = async () => {
    try {
      await dispatch(validate());
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data?.message === 'jwt expired'
      ) {
        logoutDispatch('Your session has expired. Please login again.');
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      H.identify(currentUser.id, {
        email: currentUser.email ?? '',
      });
    }

    // Add event listener for visibility change
    const handleVisibilityChange = () => {
      if (!document.hidden && currentUser) {
        validateApiOnly();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) {
      validateToken();
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, logout: logoutDispatch, currentUser }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
