import { Card, Stack, Typography } from '@mui/material';
import { transactionsWithUserAndOffer } from '../../store/slices/transactions';
import { differenceInCalendarDays, format } from 'date-fns';
// eslint-disable-next-line max-len
import TransactionProgressBar from '../../components/TransactionProgressBar/TransactionProgressBar';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { selectStatusByListingId } from '../../store/selectors/status';
import { fetchStatus } from '../../store/slices/status';
import { useEffect } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import useIsMobile from '../../hooks/useIsMobile';
import { listings } from '@prisma/client';

interface Props {
  listing: listings;
  transaction?: transactionsWithUserAndOffer;
  isPBuyer?: boolean;
}

const TransactionStatus: React.FC<Props> = ({
  listing,
  transaction,
  isPBuyer,
}) => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const closeDate = transaction?.offer?.closingDate;
  const status = useSelector((state: RootState) =>
    selectStatusByListingId(state, listing.id)
  );

  let daysToClose;
  const isClosed =
    closeDate && differenceInCalendarDays(new Date(closeDate), new Date()) < 0;
  if (closeDate) {
    const daysDifference = differenceInCalendarDays(
      new Date(closeDate),
      new Date()
    );
    if (daysDifference < 0) {
      daysToClose = 'Closed';
    } else {
      daysToClose = daysDifference;
    }
  }

  useEffect(() => {
    dispatch(fetchStatus(listing.id));
  }, [listing.id]);

  if (listing.status === 'PENDING' || listing.status === 'DRAFT') {
    return null;
  }

  return (
    <Card>
      <Stack direction="column" spacing={2} p={2} pb={isMobile ? 0 : 2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" fontWeight={600}>
            {isPBuyer ? 'Transaction Status' : 'Selling Journey'}
          </Typography>
          {daysToClose && closeDate && (
            <>
              {!isClosed ? (
                <Stack direction="row" alignItems={'center'}>
                  <Typography variant="body1" textAlign={'center'}>
                    <b>{daysToClose} days until closing</b> on{' '}
                    {format(new Date(closeDate), 'MM/dd/yyyy')}
                  </Typography>
                </Stack>
              ) : (
                <Stack direction="column" alignItems={'center'}>
                  <Typography variant="h6">Closed on</Typography>
                  <Typography variant="subtitle2">
                    {format(new Date(closeDate), 'MM/dd/yyyy')}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>
        {status && (
          <TransactionProgressBar status={status} isPBuyer={isPBuyer} />
        )}
      </Stack>
    </Card>
  );
};

export default TransactionStatus;
