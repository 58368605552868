import api from '../api';

export const authenticate = async (username: string, password: string) => {
  try {
    const response = await api.post('/authentication', {
      email: username,
      password,
      strategy: 'local',
    });
    const { accessToken, user } = response.data;
    if (accessToken) {
      localStorage.setItem('jwtToken', accessToken);
    }
    return { accessToken, user };
  } catch (error) {
    console.error('Login Error:', error);
    throw error;
  }
};

export const validate = async () => {
  const jwt = localStorage.getItem('jwtToken');
  if (!jwt) {
    throw new Error('No JWT found');
  }

  try {
    const response = await api.post('/authentication', {
      accessToken: jwt,
      strategy: 'jwt',
    });
    const { accessToken, user } = response.data;
    return { accessToken, user };
  } catch (error) {
    console.error('validate Error:', error);
    throw error;
  }
};
