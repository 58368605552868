import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import {
  Stack,
  IconButton,
  Button,
  Typography,
  Popover,
  List,
  ListItem,
  Box,
  ListItemText,
  useMediaQuery,
  useTheme,
  Badge,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store/store';
import { Close, HelpOutline, Menu } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { fetchNotes } from '../../../store/slices/notifications';
import { StyledLink } from '../../StyledLink';
import { useLocation } from 'react-router-dom';
import { selectNotesForToolbar } from '../../../store/selectors/notes';
import notificationsService from '../../../api/services/notifications';
import { useSocket } from '../../../api/socket';
import { useAuth } from '../../../contexts/AuthContext';
import { fetchMessages } from '../../../store/slices/messages';
import { fetchOffers } from '../../../store/slices/offers';
import { fetchShowings } from '../../../store/slices/showings';
import { notifications } from '@prisma/client';
import TextOrCall from '../../TextOrCall';
interface Props {
  toggleMenu: () => void;
}

const MainButtons: React.FC<Props> = ({ toggleMenu }) => {
  const notes = useSelector((state: RootState) => selectNotesForToolbar(state));
  const unreadNotes = notes.filter((note) => note.read === false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { socket, connect } = useSocket();
  const { currentUser } = useAuth();
  const isPBuyer = currentUser?.type === 'PBUYER';

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const markNotificationsRead = async () => {
    notes
      .filter((note) => note.read === false)
      .map((note) => {
        notificationsService.patch({
          ...note,
          read: true,
        });
      });
  };

  useEffect(() => {
    dispatch(fetchNotes());
    if (open) {
      markNotificationsRead();
    }
  }, [open]);

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    socket.on('messages created', () => {
      dispatch(fetchNotes());
    });
  }, [dispatch]);

  useEffect(() => {
    handleClose();
  }, [location]);

  const handleNotificationClick = (
    e: React.MouseEvent,
    notification: notifications
  ) => {
    if (isPBuyer) {
      e.preventDefault();
      dispatch(fetchMessages());
      dispatch(fetchShowings());
      dispatch(fetchOffers());
      handleClose();

      if (notification.resourceType === 'messages') {
        const element = document.getElementById('messages');
        element?.scrollIntoView({ behavior: 'auto', block: 'center' });
      }
      return;
    }

    if (!notification.link) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Stack
        direction="row"
        flex={1}
        justifyContent={'space-between'}
        alignItems={'center'}
        mr={2}
      >
        {isMobile && (
          <IconButton onClick={() => toggleMenu()}>
            <Menu htmlColor="#fff" />
          </IconButton>
        )}
        {!isMobile && <img src={'/homepoint_logo_white.png'} height={40} />}
        <Stack direction="row" spacing={1}>
          <div id="help" onClick={(e) => e.stopPropagation()}>
            <Stack
              direction="row"
              justifyContent={'center'}
              alignItems={'center'}
              pt={0.25}
              spacing={1}
            >
              <TextOrCall inverted={true} />
            </Stack>
          </div>
          <IconButton onClick={handleClick}>
            <Badge
              badgeContent={unreadNotes.length}
              sx={{
                '& .MuiBadge-badge': {
                  color: 'black',
                  backgroundColor: 'highlight.main',
                },
              }}
            >
              <NotificationsNoneIcon color="inherit" sx={{ color: '#fff' }} />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
          >
            <Box p={2} width={'100%'} sx={{ maxHeight: '80vh' }}>
              <Stack
                direction="row"
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                  position: 'sticky',
                  top: 0,
                  bgcolor: 'background.paper',
                  zIndex: 1,
                  pb: 1,
                }}
              >
                <Typography variant="body1" fontWeight={600}>
                  Notifications
                </Typography>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Stack>
              <List sx={{ maxHeight: '60vh', overflow: 'auto' }}>
                {notes.length === 0 && (
                  <Typography>You have no notifications</Typography>
                )}
                {notes.map((notification) => (
                  <StyledLink
                    to={notification.link ?? ''}
                    key={notification.id}
                    style={{ textDecoration: 'none' }}
                    onClick={(e) => handleNotificationClick(e, notification)}
                  >
                    <ListItem key={notification.id} sx={{ p: 0 }}>
                      <ListItemText
                        primary={notification.text}
                        primaryTypographyProps={{
                          variant: 'body2',
                        }}
                        secondary={new Date(
                          notification.createdAt
                        ).toLocaleString(undefined, {
                          dateStyle: 'long',
                          timeStyle: 'short',
                        })}
                        secondaryTypographyProps={{
                          variant: 'caption',
                        }}
                      />
                    </ListItem>
                  </StyledLink>
                ))}
              </List>
            </Box>
          </Popover>
        </Stack>
      </Stack>
    </>
  );
};

export default MainButtons;
