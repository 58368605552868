import {
  Stack,
  Typography,
  Box,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomTooltip } from '../CustomTooltip/CustomTooltip';
import {
  mapStatusToProgressBar,
  STEP_LABELS,
  TRANSACTION_STEPS,
} from '../../utils/mapTransactionStatus';
import { Status } from '../../types/Status';
import { InfoOutlined } from '@mui/icons-material';

interface ProgressStepProps {
  label: string;
  status: string;
  statusText: string;
  dueDate?: Date;
}

const ProgressStep: React.FC<ProgressStepProps> = ({
  label,
  status,
  statusText,
  dueDate,
}) => {
  const getStatusColor = () => {
    switch (status) {
      case 'completed':
        return '#4CAF50'; // Green
      case 'pending':
        return '#2196F3'; // Blue
      case 'on hold':
        return '#9E9E9E'; // Gray
      case 'needed':
        return '#FF9800'; // Orange
      default:
        return '#9E9E9E';
    }
  };

  return (
    <CustomTooltip title={statusText} width="300px">
      <Stack
        alignItems="center"
        spacing={1}
        sx={{ flex: 1, minWidth: '100px' }}
      >
        <Box
          sx={{
            width: '100%',
            height: 10,
            backgroundColor: getStatusColor(),
            borderRadius: 5,
            opacity: 1,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: '0.75rem', sm: '0.875rem', md: '1rem' },
            textAlign: 'center',
            wordBreak: 'break-word',
          }}
        >
          {label}
        </Typography>
        {label === 'Inspections' && dueDate && (
          <Typography variant="body2" align="center" fontWeight={600}>
            Due by {new Date(dueDate).toLocaleDateString()}
          </Typography>
        )}
      </Stack>
    </CustomTooltip>
  );
};

// New Mobile Step Component
const MobileProgressStep: React.FC<ProgressStepProps> = ({
  label,
  status,
  statusText,
}) => {
  const getStatusColor = () => {
    switch (status) {
      case 'completed':
        return '#4CAF50';
      case 'pending':
        return '#2196F3';
      case 'on hold':
        return '#9E9E9E';
      case 'needed':
        return '#FF9800';
      default:
        return '#9E9E9E';
    }
  };

  const getStatusIcon = () => {
    switch (status) {
      case 'completed':
        return <CheckIcon sx={{ fontSize: 16, color: '#fff' }} />;
      case 'pending':
        return (
          <RadioButtonUncheckedIcon sx={{ fontSize: 16, color: '#fff' }} />
        );
      case 'on hold':
        return <PauseCircleOutlineIcon sx={{ fontSize: 16, color: '#fff' }} />;
      case 'needed':
        return <ErrorOutlineIcon sx={{ fontSize: 16, color: '#fff' }} />;
      default:
        return (
          <RadioButtonUncheckedIcon sx={{ fontSize: 16, color: '#fff' }} />
        );
    }
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{
        width: '100%',
        position: 'relative',
        py: 1,
      }}
    >
      {/* Status Circle with Icon */}
      <Box
        sx={{
          width: 32,
          height: 32,
          borderRadius: '50%',
          backgroundColor: getStatusColor(),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        }}
      >
        {getStatusIcon()}
      </Box>

      {/* Label and Status */}
      <Stack spacing={0.5} sx={{ flex: 1 }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 500,
            fontSize: '0.9rem',
            lineHeight: 1.2,
          }}
        >
          {label}
        </Typography>
        <Stack direction="row" spacing={0.5} alignContent="center">
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              textTransform: 'capitalize',
            }}
          >
            {status}
          </Typography>
          <CustomTooltip title={statusText} width="300px">
            <Box onClick={(e) => e.stopPropagation()}>
              <InfoOutlined fontSize="small" />
            </Box>
          </CustomTooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface TransactionProgressBarProps {
  status: Status;
  isPBuyer?: boolean;
}

const TransactionProgressBar: React.FC<TransactionProgressBarProps> = ({
  status,
  isPBuyer = false,
}) => {
  const theme = useTheme();
  const useSmaller = useMediaQuery(theme.breakpoints.down('md'));

  if (useSmaller) {
    const filteredSteps = TRANSACTION_STEPS.filter(
      (step) => (status[step] as any)?.notApplicable !== true
    );

    // Find the closest pending/needed step, excluding 'closing'
    const currentStep =
      [...filteredSteps].find((step) => {
        const stepStatus = mapStatusToProgressBar(step, status).status;
        return (
          step !== 'closing' &&
          (stepStatus === 'pending' || stepStatus === 'needed')
        );
      }) || filteredSteps[filteredSteps.length - 1];

    return (
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            '& .MuiAccordionSummary-content': {
              margin: '2 0',
            },
          }}
        >
          <MobileProgressStep
            key={currentStep}
            label={STEP_LABELS[currentStep]}
            status={mapStatusToProgressBar(currentStep, status).status}
            statusText={mapStatusToProgressBar(currentStep, status).statusText}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            sx={{
              width: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: 15,
                top: 0,
                bottom: 0,
                width: 2,
                backgroundColor: theme.palette.divider,
                zIndex: 0,
              }}
            />
            {filteredSteps.map((step) => (
              <MobileProgressStep
                key={step}
                label={STEP_LABELS[step]}
                status={mapStatusToProgressBar(step, status).status}
                statusText={mapStatusToProgressBar(step, status).statusText}
              />
            ))}
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  }

  // Desktop version
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Stack
        direction="row"
        spacing={{ xs: 0.5, sm: 1 }}
        alignItems="flex-start"
        sx={{ width: '100%' }}
      >
        {TRANSACTION_STEPS.filter(
          (step) => (status[step] as any)?.notApplicable !== true
        ).map((step) => {
          return (
            <Stack key={step} spacing={1} sx={{ flex: 1, minWidth: '100px' }}>
              <ProgressStep
                label={STEP_LABELS[step]}
                status={mapStatusToProgressBar(step, status).status}
                statusText={mapStatusToProgressBar(step, status).statusText}
                dueDate={
                  step === 'inspection' ? status.inspection.dueDate : undefined
                }
              />
              {/* Button for potential actions for each step */}
              {/* {isPBuyer && (
                <Box
                  sx={{
                    display:
                      mapStatusToProgressBar(step, status).status === 'needed'
                        ? 'block'
                        : 'none',
                  }}
                >
                  <TransactionScheduleButton
                    buttonText="Schedule"
                    options={[
                      {
                        label: 'Mark Completed',
                        action: () => {},
                      },
                    ]}
                    sx={{ width: '100%' }}
                  />
                </Box>
              )} */}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default TransactionProgressBar;
