import { useState } from 'react';
import { Box, Slider, Typography, Stack, Card, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { formatCurrency } from '../../../utils/formatCurrency';

interface Props {
  buyer?: boolean;
}

const SimpleSavings = ({ buyer }: Props) => {
  const theme = useTheme();
  const [price, setPrice] = useState(400000);
  const traditionalFee = price * 0.03; // 6% total commission
  const homepointFee = price * 0.01; // 1% of home price
  const buyingFee = 5000;
  const savings = traditionalFee - homepointFee;
  const buyingPower = traditionalFee - buyingFee;

  const handleSliderChange = (_event: Event, newValue: number | number[]) => {
    setPrice(newValue as number);
  };

  return (
    <Stack direction="column" rowGap={2}>
      <Typography variant="h4" gutterBottom align="center" fontWeight="bold">
        Calculate Your{' '}
        <span
          style={{
            fontStyle: 'italic',
            color: theme.palette.success.main,
          }}
        >
          {buyer ? 'Buying Power' : 'Savings'}
        </span>{' '}
        with Homepoint
      </Typography>

      <Grid container alignItems="center">
        <Grid xs={12}>
          <Card sx={{ p: 4, borderRadius: 2 }}>
            <Stack spacing={2}>
              <Grid container rowGap={2}>
                <Grid xs={12} sm={5}>
                  <Typography color="text.secondary" fontWeight="bold">
                    Traditional Agent Fee (3%)
                  </Typography>
                  <Typography variant="h5" fontWeight="bold">
                    {formatCurrency(traditionalFee)}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={3}>
                  <Typography color="text.secondary" fontWeight="bold">
                    Homepoint Fee {buyer ? '' : '(1%)'}
                  </Typography>
                  <Typography variant="h5" fontWeight="bold">
                    {buyer
                      ? formatCurrency(buyingFee)
                      : formatCurrency(homepointFee)}
                  </Typography>
                </Grid>
                <Grid xs={12} sm={4}>
                  <Typography color="text.secondary" fontWeight="bold">
                    Your {buyer ? 'Buying Power' : 'Savings'} with Homepoint
                  </Typography>
                  <Typography
                    variant="h4"
                    color="success.main"
                    fontWeight="bold"
                  >
                    {buyer
                      ? formatCurrency(buyingPower)
                      : formatCurrency(savings)}
                  </Typography>
                  {buyer && (
                    <Typography variant="body1" color="text.secondary">
                      Use for closing costs, make a more competitive offer, or
                      lower your mortgage interest.
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Box>
                <Typography gutterBottom variant="h6">
                  {buyer ? 'The Home Price' : 'Your Home Price'}:{' '}
                  <b>{formatCurrency(price)}</b>
                </Typography>
                <Slider
                  value={price}
                  onChange={handleSliderChange}
                  min={200000}
                  max={1500000}
                  step={10000}
                  sx={{
                    '& .MuiSlider-thumb': {
                      width: 24,
                      height: 24,
                    },
                    '& .MuiSlider-track': {
                      height: 8,
                    },
                    '& .MuiSlider-rail': {
                      height: 8,
                    },
                  }}
                />
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SimpleSavings;
