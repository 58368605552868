import { Button, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { showings } from '@prisma/client';
import ShowingTime from '../components/ShowingTime';
import { isAfter } from 'date-fns';
import { useAuth } from '../../../../../contexts/AuthContext';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import showingsService from '../../../../../api/services/showings';
import { useAppDispatch } from '../../../../../store/store';
import { fetchShowings } from '../../../../../store/slices/showings';

interface Props {
  showing?: showings;
}

const Showing: React.FC<Props> = ({ showing }) => {
  const isPast = isAfter(
    new Date(),
    new Date(showing?.proposedTime ?? new Date())
  );
  const { id } = useParams<{ id: string }>();
  const { currentUser } = useAuth();
  const isBuyer = currentUser?.id === id;
  const [feedback, setFeedback] = useState(showing?.feedback ?? '');
  const [saved, setSaved] = useState(false);
  const dispatch = useAppDispatch();

  const saveFeedback = async (id: string, feedback: string) => {
    await showingsService.patch(id, { feedback });
    setSaved(true);
    dispatch(fetchShowings());
  };

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <Stack direction="column" spacing={1} pt={1}>
          <ShowingTime showing={showing} />
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" fontWeight={600}>
              Feedback
            </Typography>
          </Stack>
          {isBuyer && isPast && !saved ? (
            <Stack direction="row" spacing={1}>
              <TextField
                multiline
                minRows={1}
                maxRows={5}
                fullWidth
                value={feedback}
                onChange={handleFeedbackChange}
                placeholder="Enter any showing feedback you have."
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => saveFeedback(showing?.id ?? '', feedback)}
              >
                Save
              </Button>
            </Stack>
          ) : (
            <Typography variant="body2">
              {showing?.feedback ?? 'None'}
            </Typography>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Showing;
