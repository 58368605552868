import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  authenticate,
  validate as validateAuth,
} from '../../api/services/authentication';
import { users } from '@prisma/client';
import { AxiosError } from 'axios';

interface State {
  user: users | null;
  loading: boolean;
  error: string | null;
}

const initialState: State = {
  user: null,
  loading: true,
  error: null,
};

export const login = createAsyncThunk(
  'auth/login',
  async (
    credentials: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await authenticate(
        credentials.email,
        credentials.password
      );
      const user = response.user;
      if (!user.verified) {
        return rejectWithValue(
          // eslint-disable-next-line max-len
          'Your email has not been verified. Please check your email for your verification email. If you cannot find it, you can reset your password to receive a new email.'
        );
      }
      return user;
    } catch (error) {
      return rejectWithValue(
        // eslint-disable-next-line max-len
        'Unable to log in. Please double-check your email and password and try again. If you did not complete your account setup, you may reset your password to receive a new email.'
      );
    }
  }
);

export const validate = createAsyncThunk(
  'auth/validate',
  async (_, { rejectWithValue }) => {
    try {
      const response = await validateAuth();
      return response.user;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error.response?.data?.message === 'jwt expired'
      ) {
        return rejectWithValue('Your session has expired. Please login again.');
      }
      return rejectWithValue('Login failed');
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state, action: PayloadAction<string | undefined>) => {
      localStorage.removeItem('jwtToken');
      state.user = null;
      state.error = action.payload ?? null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(login.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(validate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(validate.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(validate.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
