import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from 'react-router-dom';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { routes as appRoutes } from './routes/app';
import { routes as landingRoutes } from './routes/landing';
import { routes as loginRoutes } from './routes/logins';
import MiniDrawer from './components/Drawer/MiniDrawer';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { H } from 'highlight.run';
import { Provider, useSelector } from 'react-redux';
import { RootState, store } from './store/store';
import NiceModal from '@ebay/nice-modal-react';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { BackButtonProvider } from './contexts/BackButtonContext';
import PublicLayout from './layouts/PublicLayout';
import useVersionCheck from './hooks/useVersionCheck';

const newShade = (hexColor: string, magnitude: number) => {
  hexColor = hexColor.replace('#', '');
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

const ProtectedRoute = ({
  sellerOnly = true,
  children,
}: {
  sellerOnly?: boolean;
  children: JSX.Element;
}) => {
  const { isAuthenticated } = useAuth();
  const loading = useSelector((state: RootState) => state.auth.loading);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();

  if (loading) {
    return <CircularProgress />;
  }

  if (!isAuthenticated) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that location after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (sellerOnly && currentUser?.type === 'PBUYER') {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export function App() {
  const { palette } = createTheme();
  useVersionCheck();
  const theme = createTheme({
    components: {
      // todo: remove once the issue is addressed: https://github.com/mui/material-ui/issues/31185
      MuiDialogContent: {
        styleOverrides: { root: { paddingTop: '4px !important' } },
      },
    },
    typography: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: 16,
      button: {
        textTransform: 'none',
      },
    },
    palette: {
      primary: {
        light: '#63b8ff',
        main: '#279AF1',
        dark: '#005db0',
        contrastText: '#fff',
      },
      secondary: {
        main: '#2B4570',
        light: newShade('#2B4570', 25),
        dark: newShade('#2B4570', -25),
        contrastText: '#000',
      },
      success: {
        main: '#27ae60',
        contrastText: '#fff',
      },
      highlight: palette.augmentColor({ color: { main: '#f39c12' } }),
    },
  });

  H.init('jdk07q7e', {
    serviceName: 'frontend-app',
    tracingOrigins: ['localhost', 'api.homepoint.co'],
    privacySetting: 'none',
    manualStart: true,
    networkRecording: {
      recordHeadersAndBody: true,
      urlBlocklist: [
        // insert full or partial urls that you don't want to record here
        // Out of the box, Highlight will not record these URLs (they can be safely removed):
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
      ],
    },
  });

  if (import.meta.env.PROD) {
    H.start();
  }

  const options = {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  };

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PostHogProvider
          apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <BrowserRouter>
            <CssBaseline />
            {/* <GlobalStyles /> */}
            <BackButtonProvider>
              <SnackbarProvider>
                <FlagsmithProvider
                  options={{
                    environmentID: import.meta.env.VITE_FLAG_ENVIRONMENT_ID,
                  }}
                  flagsmith={flagsmith}
                >
                  <Suspense fallback={<CircularProgress />}>
                    <Routes>
                      <Route>
                        {loginRoutes.map((route) => (
                          <Route
                            key={route.key}
                            path={route.path}
                            element={<route.component />}
                          />
                        ))}
                      </Route>
                      <Route element={<PublicLayout />}>
                        {landingRoutes.map((route) => (
                          <Route
                            key={route.key}
                            path={route.path}
                            element={<route.component />}
                          />
                        ))}
                      </Route>
                      <Route
                        element={
                          <AuthProvider>
                            <NiceModal.Provider>
                              <MiniDrawer />
                            </NiceModal.Provider>
                          </AuthProvider>
                        }
                      >
                        {appRoutes.map((route) => (
                          <Route
                            key={route.key}
                            path={route.path}
                            element={
                              route.protected ? (
                                <ProtectedRoute sellerOnly={route.sellerOnly}>
                                  <route.component />
                                </ProtectedRoute>
                              ) : (
                                <route.component />
                              )
                            }
                          />
                        ))}
                      </Route>
                      <Route
                        path="*"
                        element={<Navigate to="/404" replace />}
                      />
                    </Routes>
                  </Suspense>
                </FlagsmithProvider>
              </SnackbarProvider>
            </BackButtonProvider>
            {/* <Footer /> */}
          </BrowserRouter>
        </PostHogProvider>
      </Provider>
    </ThemeProvider>
  );
}

export function WrappedApp() {
  return <App />;
}
